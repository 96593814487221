import React, { useEffect, useState } from 'react';
import './Sublogin.scss';
import {
	DynamicForm,
	SuppliersInput,
	Button
} from '@abm-international/react-components';
import { useSelector } from 'react-redux';
import { t } from './../../libraries/i18n';
import useMetadata from './../../api/useMetadata';
import { createSublogin } from './../../api/sublogins';
import { Associate } from './../../interfaces/settings/sublogin';
import { selectAccountId } from './../../slices/userSlice';
import ButtonLink from '../../common/ButtonLink/ButtonLink';
import { ReactComponent as Arrow } from './../../assets/arrow.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';

export default function SubloginNew() {
	const accountId = useSelector(selectAccountId);
	const { permissions, groupedPermissions, suppliers } = useMetadata(accountId);
	const [newSublogin, setNewSublogin] = useState<any>(null);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const history = useHistory();

	useEffect(() => {
		if (!newSublogin) setNewSublogin({ associates: [ ...suppliers.map((s: any) => s.id) ] });
	}, [newSublogin, suppliers]);

	const accountFields = [
		{
			title: t('sublogins.title_email'),
			name: 'email',
			type: 'email',
			placeholder: t('sublogins.placeholder_email')
		},
		{
			title: t('sublogins.title_name'),
			name: 'name',
			type: 'text',
			placeholder: t('sublogins.placeholder_name')
		},
		{
			title: t('sublogins.title_language'),
			name: 'language',
			type: 'select',
			options: {
				choices: [
					{
						title: t('common.nl'),
						value: 'nl'
					},
					{
						title: t('common.fr'),
						value: 'fr'
					},
					{
						title: t('common.de'),
						value: 'de'
					},
					{
						title: t('common.en'),
						value: 'en'
					}
				]
			}
		}
	];

	const accountSections = [
		{
			title: t('sublogins.title_section_main'),
			fields: [
				'email',
				'name',
				'language'
			]
		},
	];

	const getSuppliersById = () => {
		const byId: Record<string, Associate> = {};
		suppliers?.forEach((supplier: Associate) => byId[supplier.id.toString()] = supplier);
		return byId;
	};

	const suppliersInputField = {
		title: t('sublogins.title_suppliers'),
		name: 'associates',
		type: 'custom',
		placeholder: t('sublogins.placeholder_suppliers'),
		options: {
			component: SuppliersInput,
			noLabel: true,
			customProps: {
				suppliers,
				suppliersById: getSuppliersById()
			}
		}
	};

	const getPermissionsTranslation = (translationCode: string) => {
		const code = translationCode.replace('permissions.', '');
		const newCode = code.split('.').join('_');
		return t(`permissions.${newCode}`);
	};

	const permissionField = {
		title: t('sublogins.title_permissions'),
		name: 'permissions',
		type: 'permissions',
		options: {
			noLabel: true,
			usePermissions: () => ({
				permissions,
				groupedPermissions,
			}),
			t: getPermissionsTranslation
		}
	};

	const getFields = () => {
		let fields: any = [...accountFields];
		if (suppliers && suppliers.length > 1) fields = [...fields, suppliersInputField];

		return [
			...fields,
			permissionField
		];
	};

	const getSections = () => {
		let sections: any = [...accountSections];
		if (suppliers && suppliers.length > 1) {
			sections = [
				...sections,
				{
					title: t('sublogins.title_section_suppliers'),
					fields: [
						'associates'
					]
				}
			];
		}

		return [
			...sections,
			{
				title: t('sublogins.title_section_permissions'),
				fields: [
					'permissions'
				]
			}
		];
	};

	const hasChanges = () => JSON.stringify(newSublogin) !== '{}';

	const submit = async () => {
		setIsSubmitting(true);
		await createSublogin(accountId, newSublogin);
		setIsSubmitting(false);
		history.push('/sublogins');
	};

	return (
		<div className='Sublogin SubloginEdit'>
			<ButtonLink to={'/sublogins'} textOnly noUnderline className='back'>
				<Arrow className={'arrow arrow-left'} />
				{t(hasChanges() ? 'sublogins.button_to_overview_cancel' : 'sublogins.button_to_overview')}
			</ButtonLink>
			<h1>{t('sublogins.title_new_sublogin')}</h1>

			{permissions && groupedPermissions && suppliers &&
				<DynamicForm
					fields={getFields()}
					values={newSublogin}
					sections={getSections()}
					onChange={setNewSublogin}
				/>
			}

			<Button
				disabled={!hasChanges() || isSubmitting}
				onClick={submit}
			>
				<FontAwesomeIcon icon={faCheck} />
				{t('sublogins.button_save')}
			</Button>
		</div>
	);
}
