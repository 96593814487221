import React from 'react';
import './ProductHeader.scss';
import { translate } from '../../libraries/i18n';

export default function ProductHeader({ product }){
	return (
		<h1 className={'ProductHeader'}>
			{product.reference && (
				<span className={'id'}>
					{product.reference}
				</span>
			)}

			<span className={'name'}>
				{translate(product.nameTranslations)}
			</span>
		</h1>
	);
}
