import useSWR from 'swr';
import { fetcher } from './index';
import { Permission } from '../interfaces/permissions';

const getPermissionTypes = (permissions: Array<Permission>, groupName: string) => {
	const groupPermissions = permissions.filter(p => p.group === groupName);

	const typeNames = groupPermissions.reduce((names: Array<string>, p) => {
		if (!names.includes(p.type)) return [...names, p.type];
		return names;
	}, []);

	return typeNames
		.map(typeName => ({
			type: typeName,
			permissions: groupPermissions
				.filter(p => p.type === typeName)
				.sort((a, b) => a.level - b.level)
		}));
};

const getPermissionsGroups = (permissions: Array<Permission>, groups: Array<string>) => groups.map(groupName => ({
	group: groupName,
	types: getPermissionTypes(permissions, groupName)
}));

const transformSuppliers = (data: any) => {
	if (!data) return [];

	return data.map((s: any) => ({
		...s,
		id: s.id.toString()
	}));
};

export default function useMetadata(accountId: string) {
	const { data, error } = useSWR(accountId && `/manage.supplier.sub.accounts/${accountId}/metadata`, fetcher);

	return {
		permissions: data?.permissions,
		groupedPermissions: data && getPermissionsGroups(data.permissions, data.groups),
		suppliers: transformSuppliers(data?.associates),
		isLoading: !data && !error,
		error
	};
}
