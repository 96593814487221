import React from 'react';
import { Portal } from 'react-portal';
import './portalModal.scss';

function PortalModal(props) {
	const handleModalClick = (e) => e.stopPropagation();

	const shouldShowCloseButton = () => {
		return !props.hideCloseButton && !props.bare;
	};

	return (
		<Portal>
			<div className={`PortalModal ${props.hideCloseButton ? 'minimal-padding' : ''} ${props.bare ? 'bare' : ''}`} onClick={handleModalClick}>
				<div className={'modal__backdrop'} onClick={props.close}></div>
				<div className={'modal__body'}>
					{shouldShowCloseButton() && (
						<div className={'modal__close'} onClick={props.close}>
							&#10005;
						</div>
					)}
					<div className={'modal__content'}>
						{props.children}
					</div>
				</div>
			</div>
		</Portal>
	);
}

export default PortalModal;
