import fetch from 'cross-fetch';
import { config } from '../config';
import { logger } from './logger/logger';
import ResponseError from './errors/customError';
import store from './../store';
import { setErrorTable } from '../slices/errorSlice';

export const get = endpoint => method('GET', endpoint, null);

export const post = (endpoint, body, opts = {}) => method('POST', endpoint, body, opts);

export const method = async (method, endpoint, body, opts) => {
	const options = {
		...opts,
		credentials: 'include',
		method: method.toUpperCase(),
	};

	let bodyObject = body;

	if (body) {
		options.body = body;

		try {
			bodyObject = JSON.parse(body);
		} catch (error) {
			logger.verbose('body is not JSON, cannot convert', { body });
		}
	}
	try {
		const response = await fetch(config.api + endpoint, options);
		const res = await parseApiResponse(response);

		logger.debug(`${method} [${res.result?.status}] ${endpoint}`, getLoggingMeta(bodyObject, endpoint, options, res));

		if (res.result.status >= 400) {
			throw ResponseError.getErrorFromResponse(res) ?? res;
		}
		return res.body;
	} catch (e) {
		if ((e.code === 1003 || e.result?.status === 401) && !window.location.href.includes('auth')) {
			window.location.href = `/auth/login?redirect=${window.location.pathname}`;
		}
		logger.error(`${method} ERROR`, getLoggingMeta(bodyObject, endpoint, options, e));
		throw e;
	}
};

export const parseApiResponse = async response => {
	const contentType = response.headers.get('content-type').toLowerCase().replace('; charset=utf-8', '');

	switch (contentType) {
		case 'text/html': {
			// handle php fuckups
			response.blob().then(b => b.text().then(t => store.dispatch(setErrorTable({ html: t }))));
			return {
				result: {
					status: 500,
				},
			};
		}
		case 'application/json': {
			return {
				result: response,
				body: await response.json(),
			};
		}
		default:
			return { result: response };
	}
};

const getLoggingMeta = (bodyObject, endpoint, options, res) => {
	if (bodyObject && bodyObject.password) {
		bodyObject.usingPassword = !!bodyObject.password;
		delete bodyObject.password;
	}

	return {
		endpoint: config.api + endpoint,
		body: bodyObject ? bodyObject : 'No body to show (this is not necessarily an issue)',
		options: options,
		res: res,
	};
};

const MANAGE_COMMERCIAL_DATA = 'manage.supplier.commercial.data';

export const saveCommercialProduct = (supplierId, productId, details) => post(`/${MANAGE_COMMERCIAL_DATA}/${supplierId}/articles/${productId}`, JSON.stringify(details));

export const getTechnicalProducts = supplierId => get(`/suppliers/${supplierId}/articles`);

export const saveProduct = (supplierId, productId, detail, type) => {
	switch (type) {
		case 'commercial':
			return saveCommercialProduct(supplierId, productId, detail);
		case 'technical':
			return saveTechnicalProduct(supplierId, productId, detail);
		default:
			throw new Error(`unknown product type: ${type}`);
	}
};

export const saveTechnicalProduct = (supplierId, productId, details) => post(`/suppliers/${supplierId}/articles/${productId}`, JSON.stringify(details));

export const forgotPassword = async (username, language) => {
	const response = await post('/forgotten/', JSON.stringify({ username, language }));
	if (!response.success) throw response;
	return response;
};

export const isAuthenticated = () => get('/me');

export const logout = () => post('/v1/auth/logout');

export const authenticate = async (username, password) => {
	const credentials = JSON.stringify({
		username: username,
		password: password,
	});
	const res = await post('/v1/auth/login', credentials, {
		headers: { 'Content-type': 'application/json' },
	});

	if (!res?.success) {
		throw res;
	}

	// We are using the new /v1/auth/login which doesn't return the full session
	// if (res.session && res.session.userId) {
	// 	return {
	// 		id: res.session.userId,
	// 	};
	// } else {
	// 	throw res;
	// }

	return res;
};

export const checkAuth = async () => {
	const res = await get('/v1/auth/check');
	return res;
};

export const getExcel = supplier => get(`/suppliers/${supplier}/excel`);

export const httpLog = (data, endpoint) =>
	fetch(endpoint, {
		method: 'POST',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/json',
		},
	});

export const saveProductSpecifications = (supplierId, productId, language, specifications) =>
	post(`/suppliers/${supplierId}/articles/${productId}/specifications/${language}`, specifications);

export const saveProductVvoDocs = (supplierId, productId, language, vvoDocs) => post(`/v1/suppliers/${supplierId}/articles/${productId}/vvodocs/${language}`, vvoDocs);

export const uploadImage = (supplierId, productId, type, image) => post(`/manage.supplier.commercial.data/${supplierId}/articles/${productId}/${type}/image`, image);

export const uploadExcel = (supplierId, excel) => post(`/suppliers/${supplierId}/excel`, excel);

export const getSuppliers = () => get('/suppliers/?suppliers=suppliersportal');
