import React from 'react';
import { t } from '../../libraries/i18n';
import './ProductNavigator.scss';
import ButtonLink from './../ButtonLink/ButtonLink';
import { ReactComponent as Arrow } from './../../assets/arrow.svg';

export default function ProductNavigator(props) {
	return (
		<nav className={'ProductNavigator'}>
			{props.left && props.left.permission !== false &&
				<ButtonLink to={props.left.url} textOnly noUnderline className='back'>
					<Arrow className={'arrow arrow-left'} />
					{t(props.left.text)}
				</ButtonLink>
			}

			{props.right && props.right.permission !== false &&
				<ButtonLink to={props.right.url} textOnly noUnderline className='forward'>
					{t(props.right.text)}
					<Arrow className={'arrow arrow-right'} />
				</ButtonLink>
			}
		</nav>
	);
}
