import useSWR from 'swr';
import { fetcher } from './index';

export default function useSuppliers() {
	const { data, error } = useSWR('/me', fetcher);

	return {
		me: data,
		isLoading: !data && !error,
		error
	};
}
