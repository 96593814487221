import { createSlice } from '@reduxjs/toolkit';
import {
	forgotPassword,
	isAuthenticated,
} from '../libraries/api';
import * as i18n from './../libraries/i18n';

const initialState = {
	customers: [],
	routes: [],
	roles: ['technical'],
	forgot: { errors: null, loading: false, succeeded: false }
};

const USER_PROPERTIES = ['id', 'company', 'account', 'customer', 'customers', 'username', 'routes', 'roles', 'session', 'language'];
export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		getUserStart: (state) => {
			state.fetching = true;
		},
		getUserSuccess: (state, { payload }) => {
			const { user } = payload;
			USER_PROPERTIES.forEach( item => {
				state[item] = user[item];
			});
			state.fetching = false;
			state.error = null;
		},
		getUserFailed: (state, { payload }) => {
			state.fetching = false;
			if (payload?.error) {
				state.error = payload.error;
			}
		},
		logoutSuccess: state => {
			USER_PROPERTIES.forEach( item => {
				state[item] = initialState[item] ?? undefined;
			});
			state.error = null;
		},
		setLocaleSuccess: (state, { payload }) => {
			const { locale } = payload;
			state.locale = locale;
		},
		forgotPost: state => {
			state.forgot.errors = null;
			state.forgot.loading = true;
		},
		forgotSuccess: state => {
			state.forgot.loading = false;
			state.forgot.succeeded = true;
		},
		forgotReject: (state) => {
			state.forgot.errors = [...(state.forgot.errors ?? []), { message: 'common.error_unknown' }];
			state.forgot.loading = false;
			state.forgot.succeeded = false;
		}
	}
});

export const {
	getUserStart,
	getUserSuccess,
	getUserFailed,
	logoutSuccess,
	setLocaleSuccess,
	forgotPost,
	forgotSuccess,
	forgotReject
} = userSlice.actions;

export const selectUser = state => state.user;
export const selectAccountId = state => state.user.account;
export const selectHasPermission = (state, permission) => state.user.roles.includes(permission);
export const selectHasTechnicalPermission = (state) => selectHasPermission(state, 'technical');
export const selectHasSuperUserPermission = (state) => selectHasPermission(state, 'SUPPLIERS-SUPERUSER');
export const selectIsAuthenticated = (state) => Boolean(state.user.id);
export const selectLocale = (state) => state.user.locale ?? state.user.language;
export const selectForgotErrors = state => state.user.forgot.errors;
export const selectForgotLoading = state => state.user.forgot.loading;
export const selectForgotSucceeded = state => state.user.forgot.succeeded;
export const selectIsAuthenticating = state => state.user.fetching;
export const selectAuthenticateErrors = state => state.user.error ?? null;


export const fetchUserIfNeeded = () => async (dispatch, getState) => {
	const state = getState();
	if (!selectIsAuthenticated(state) && !selectIsAuthenticating(state)){
		dispatch(getUserStart());
		try {
			const user = await isAuthenticated();
			if (user){
				dispatch(getUserSuccess({ user }));
				dispatch(setLocale({ locale: user.language } ));
			} else {
				dispatch(getUserFailed());
			}
		} catch (e) {
			dispatch(getUserFailed(/*{ error: e }*/));
		}

	}
};

export const setLocale = ({ locale }) => async dispatch => {
	try {
		await i18n.setLocale(locale);
		dispatch(setLocaleSuccess({ locale }));
	} catch (e){
		//TODO: log error
	}
};

export const forgot = ({ username }) => async (dispatch, getState) => {
	const state = getState();
	const locale = selectLocale(state) ?? 'nl';
	dispatch(forgotPost());
	try {
		await forgotPassword(username, locale);
		dispatch(forgotSuccess());
	} catch (e) {
		dispatch(forgotReject());
	}
};

export default userSlice.reducer;
