import React from 'react';
import { t } from './../../libraries/i18n';
import { formatDate } from './../../libraries/dateUtils';
import './StatusLabel.scss';

export default function StatusLabel(props) {
	const {
		value,
		since,
		user,
		module
	} = props;

	return (
		<div className={'StatusLabel'}>
			<span className={`status status--${value}`} data-status={value}>
				{t(`${module ?? 'commercial'}.label_status_${value}`)}
			</span>
			{since && (
				<span className={'date'}>
					{formatDate(since)}
				</span>
			)}
			{user && (
				<span className={'user'}>
					{t(`${module ?? 'commercial'}.label_user`)}{' '}
					<span className='name'>{user}</span>
				</span>
			)}
		</div>
	);
}
