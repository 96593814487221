import useSWR from 'swr';
import {
	Submission,
	MetadataInput,
	MetadataCategory
} from './../interfaces/commercial';
import { fetcher, transformProduct } from './index';

const endpoint = 'manage.supplier.commercial.data';

const transformSubmission = ({
	status,
	value,
	version,
	registeredBy,
	registeredOn,
	processedBy,
	processedOn,
	reasons,
	category,
	type,
	subtype,
	letCharlesDoIt
}: any): Submission => {
	const submission: Submission = {
		status,
		category,
		type,
		subtype,
		version,
		value,
		reasons,
		letCharlesDoIt
	};
	if (registeredOn) {
		submission.registered = {
			user: registeredBy,
			date: new Date(registeredOn)
		};
	}
	if (processedOn) {
		submission.processed = {
			user: processedBy,
			date: new Date(processedOn)
		};
	}
	return submission;
};

const transformSubmissions = (data: any) => data?.commercial.data.history.map(transformSubmission) ?? [];

const transformMetadata = (data: MetadataInput): { categories?: Array<MetadataCategory>, photoLocation?: string } => {
	if (!data) return {};

	const categories = Object.entries(data.categories).map(([key, value]): any => {
		const types = Object.entries(value.types).map(([key, value]) => ({
			type: key,
			amounts: value
		}));

		return {
			category: key,
			types
		};
	});

	return {
		categories,
		photoLocation: data.categories.images.location,
	};
};

export function useProduct(supplierId: string, productId: string) {
	const { data, error, mutate } = useSWR(`/${endpoint}/${supplierId}/articles/${productId}`, fetcher);

	return {
		product: transformProduct(data),
		submissions: transformSubmissions(data),
		isLoading: !error && !data,
		error,
		mutate
	};
}

export function useProducts(supplierId: string) {
	const { data, error, mutate } = useSWR(`/${endpoint}/${supplierId}/articles`, fetcher);

	return {
		products: data ?? [],
		isLoading: !error && !data,
		error,
		mutate
	};
}

export function useMetadata(): { categories?: Array<MetadataCategory>, photoLocation?: string, loading: boolean, error: any } {
	const { data, error } = useSWR(`/${endpoint}/metadata`, fetcher);

	return {
		...transformMetadata(data),
		loading: !error && !data,
		error
	};
}
