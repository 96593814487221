import React from 'react';
import './Sublogins.scss';
import { useSelector } from 'react-redux';
import useSupplierRoute from '../../libraries/useSupplierRoute';
import { useSublogins } from './../../api/sublogins';
import {
	List
} from '@abm-international/react-components';
import { Account } from './../../interfaces/settings/sublogin';
import { useHistory } from 'react-router';
import { t } from '../../libraries/i18n';
import { selectAccountId } from '../../slices/userSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faPencilAlt, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import ButtonLink from './../../common/ButtonLink/ButtonLink';

export default function Sublogins() {
	useSupplierRoute();
	const accountId = useSelector(selectAccountId);
	const { sublogins, error } = useSublogins(accountId);
	const history = useHistory();

	const listHeader = [
		{
			key: 'name',
			title: 'Naam',
			render: ({ name, loginEnabled }: Account) => (
				<span className={!loginEnabled ? 'disabled' : ''}>
					{!loginEnabled && <FontAwesomeIcon icon={faBan} />}
					{name}
				</span>
			)
		},
		{
			key: 'email',
			title: 'Email',
			render: ({ email }: Account) => email
		},
		{
			key: 'action',
			title: '',
			render: ({ id }: Account) =>
				<ButtonLink
					to={`/sublogins/${id}/edit`}
					stopPropagation
					icon={<FontAwesomeIcon icon={faPencilAlt} />}
				>
					{t('sublogins.button_edit')}
				</ButtonLink>
		}
	];

	// const renderSubloginsList = () => (
	// 	<div className='sublogins__list'>
	// 		{sublogins.map((sublogin: Account) => (
	// 			<div className='list__item'>
	// 				<div className='name'>{sublogin.name}</div>
	// 				<div className='email'>{sublogin.email}</div>

	// 			</div>
	// 		))}
	// 	</div>
	// );

	return (
		<div className='Sublogins'>
			<h2>{t('sublogins.title_overview')}</h2>

			<div className='list__actions'>
				<ButtonLink to='/sublogins/new'>
					<FontAwesomeIcon icon={faUserPlus} />
					{t('sublogins.button_create_new_sublogin')}
				</ButtonLink>
			</div>

			{error && (
				<div className={'message'}>
					<span role='img' aria-label='error emoji'>💥</span>
					{t('sublogins.label_error_loading_overview')}
				</div>
			)}

			{sublogins?.length <= 0 && (
				<div className={'message'}>
					{t('sublogins.label_no_sublogins_found')}
				</div>
			)}

			{sublogins?.length > 0 && (
				<List
					header={listHeader}
					content={sublogins}
					action={(_: any, { id }: Account) => {
						history.push(`/sublogins/${id}`);
					}}
					gridTemplateColumns={'1fr 1fr 14rem'}
				/>
			)}

			{/* {sublogins?.length > 0 && renderSubloginsList()} */}
		</div>
	);
}
