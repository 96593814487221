import React, { useEffect } from 'react';
import { Spinner } from '@abm-international/react-components';
import * as api from './../../../libraries/api';
import { useDispatch, useSelector } from 'react-redux';
import { logoutSuccess, selectIsAuthenticated } from '../../../slices/userSlice';
import { Redirect } from 'react-router-dom';

export const Logout = () => {
	const dispatch = useDispatch();
	const isAuthenticated = useSelector(selectIsAuthenticated);

	useEffect(() => {

		async function logout(){
			try {
				await api.logout();
			} catch (e) {
				console.error(e);
			}

			dispatch(logoutSuccess());
			localStorage.clear();
			sessionStorage.clear();
		}
		logout();
	}, [dispatch]);


	if (!isAuthenticated ) return <Redirect to={'/auth/login'} />;
	return <Spinner />;
};
