import React, { useEffect, useMemo, useState } from 'react';
import './TechnicalDetail.scss';
import { getLocale, t } from './../../../libraries/i18n';
import Allergens from './allergens/allergens';
import ProductSpecifications from './productSpecifications/productSpecifications';
import { Checkbox, Spinner } from '@abm-international/react-components';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import useSupplierRoute from '../../../libraries/useSupplierRoute';
import { useProduct } from '../../../api/technical';
import { selectSupplierId } from '../../../slices/suppliersSlice';
import SaveButton from './../SaveButton/SaveButton';
import { ProductHeader, ProductNavigator } from '../../../common';
import { TechnicalStatus } from '../../../interfaces/technical';
import * as api from '../../../libraries/api';
import { toast } from 'react-toastify';
import VvoDocs from './vvoDocs/vvoDocs';

export default function TechnicalDetail() {
	useSupplierRoute();


	const { id } = useParams<{ id: string }>();
	const history = useHistory();

	const supplierId = useSelector(selectSupplierId);

	const [locales, setLocales] = useState([
		{ lang: 'nl', selected: false },
		{ lang: 'fr', selected: false },
		{ lang: 'de', selected: false },
		{ lang: 'en', selected: false }
	]);

	const [localesVvoDocs, setLocalesVvoDocs] = useState([
		{ lang: 'nl', selected: false },
		{ lang: 'fr', selected: false },
		{ lang: 'de', selected: false },
		{ lang: 'en', selected: false }
	]);

	const {
		product,
		status,
		allergens,
		specifications,
		vvoDocs,
		allergenDefinitions,
		isLoading,
		error,
		mutate
	} = useProduct(supplierId, id);

	const [hasCheckedTerms, setCheckedTerms] = useState(false);
	const [isEditable, setEditable] = useState(false);
	const [specificationSaving, setSpecificationSaving] = useState<Array<string>>([]);
	const [vvoDocsSaving, setVvoDocsSaving] = useState<Array<string>>([]);
	const [saving, setSaving] = useState(false);

	useEffect(() => {
		if (status && status !== TechnicalStatus.APPROVED) {
			setEditable(true);
		} else if (status && status === TechnicalStatus.APPROVED) {
			setEditable(false);
		}
	}, [status]);

	useEffect(() => {
		if (error && error?.code === 1005) history.push('/products/technical');
	}, [error, history]);


	const getProductName = (product: { id: string, reference: string, code: string, nameTranslations: { [lang: string]: string } } | undefined) => {
		if (!product) return 'Loading...';
		const locale = getLocale();
		let productName = product.reference ? `[${product.reference}] ` : '';
		productName += (product.nameTranslations[locale] || '');
		return productName;
	};
	document.title = `${getProductName(product)} | Charles Supplier Portal`;

	const setAllergens = (allergens: Record<string, boolean>) => {
		mutate({ allergens }, false);
	};

	const handleFileChange = async (file: any, language: string) => {
		setSpecificationSaving([...specificationSaving, language]);
		try {
			const specificationData = new FormData();
			specificationData.append('prodspec', file);
			const res = await api.saveProductSpecifications(supplierId, id, language, specificationData);
			mutate({ specifications: [...specifications, { name: res.name, url: res.url, language }] }, false);
		} catch (e) {
			toast.error('specification saving failed');
		}
		setSpecificationSaving(specificationSaving.filter(lang => lang !== language));
	};

	const handleFileChangeVvoDocs = async (file: any, language: string) => {
		setVvoDocsSaving([...vvoDocsSaving, language]);
		try {
			const vvoDocsData = new FormData();
			vvoDocsData.append('vvoDocs', file);
			const res = await api.saveProductVvoDocs(supplierId, id, language, vvoDocsData);
			mutate({ vvodocs: [...vvoDocs, { name: res.name, url: res.url, language }] }, false);
		} catch (e) {
			toast.error('vvoDocs saving failed');
		}
		setVvoDocsSaving(vvoDocsSaving.filter(lang => lang !== language));
	};

	const handleFileRemove = (lang: string) => {
		mutate({ specifications: specifications.filter(({ language }: Record<string, string>) => language !== lang) }, false);
	};

	const handleFileRemoveVvoDocs = (lang: string) => {
		mutate({ vvodocs: vvoDocs.filter(({ language }: Record<string, string>) => language !== lang) }, false);
	};

	const handleUpdateExpireDate = (langSpec: { lang: string, selected: boolean }) => {
		setLocales((oldLocales) => {
			const newLocales = oldLocales.map(o => {
				if (langSpec.lang !== o.lang) {
					return o;
				}
				return { ...o, selected: langSpec.selected };
			});
			return newLocales;
		});
	};

	const handleUpdateExpireDateVvoDocs = (langSpec: { lang: string, selected: boolean }) => {
		setLocalesVvoDocs((oldLocales) => {
			const newLocales = oldLocales.map(o => {
				if (langSpec.lang !== o.lang) {
					return o;
				}
				return { ...o, selected: langSpec.selected };
			});
			return newLocales;
		});
	};

	const disabled = !isEditable && (status === TechnicalStatus.APPROVED);

	const canApprove = useMemo(() => {
		if (
			!isEditable ||
			!hasCheckedTerms ||
			![...specifications, ...vvoDocs]?.some(({ url }: Record<string, string>) => url) ||
			!Object.values(allergens).length
		) {
			return false;
		}

		return true;
	}, [isEditable, hasCheckedTerms, specifications, vvoDocs, allergens]);

	return (
		<div className='TechnicalDetail'>
			<ProductNavigator
				left={{
					url: '/products/technical',
					text: 'technical.button_to_overview'
				}}
			/>
			{isLoading && <Spinner />}
			{!isLoading && !error && (
				<>
					<ProductHeader product={product} />
					<Allergens
						allergens={allergens ?? {}}
						definitions={allergenDefinitions}
						setAllergens={setAllergens}
						disabled={disabled}
					/>

					<ProductSpecifications
						languages={locales}
						handleUpdateExpireDate={handleUpdateExpireDate}
						files={specifications}
						handleFileChange={handleFileChange}
						handleFileRemove={handleFileRemove}
						disabled={disabled}
						isSaving={specificationSaving}
					/>
					<VvoDocs
						languages={localesVvoDocs}
						handleUpdateExpireDate={handleUpdateExpireDateVvoDocs}
						files={vvoDocs}
						handleFileChange={handleFileChangeVvoDocs}
						handleFileRemove={handleFileRemoveVvoDocs}
						disabled={disabled}
						isSaving={vvoDocsSaving}
					/>
					{(isEditable || (status !== TechnicalStatus.APPROVED)) && (
						<Checkbox
							id={'checkbox__terms'}
							className={'terms'}
							checked={hasCheckedTerms}
							onToggle={() => setCheckedTerms(!hasCheckedTerms)}
						>
							<span>
								{t('common.agree_with_terms')} <a href={'/Terms'} target={'_blank'} rel={'noopener noreferrer'}>{t('common.terms_title')}</a>.
							</span>
						</Checkbox>
					)}
					<SaveButton
						allergens={allergens}
						allergenDefinitions={allergenDefinitions}
						locales={locales}
						specifications={specifications}
						localesVvoDocs={localesVvoDocs}
						vvoDocs={vvoDocs}
						id={id}
						mutate={mutate}
						useSaving={[saving, setSaving]}
						canApprove={canApprove}
						canSave={status !== TechnicalStatus.APPROVED && status !== TechnicalStatus.ALMOST_EXPIRED}
						isEditable={isEditable}
						setEditable={setEditable}
					/>
				</>
			)}
		</div>

	);
}
