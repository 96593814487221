import React from 'react';
import './vvoDocs.scss';
import { t } from '../../../../libraries/i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FileUpload from './../../../../common/FileUpload/FileUpload';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { Checkbox } from '@abm-international/react-components';

const VvoDocs = props => {
	const {
		languages, handleFileRemove, disabled, isSaving, handleFileChange, handleUpdateExpireDate, files
	} = props;

	const TEMPORARILY_STORED = '2500';
	const handleCheckboxChange = (index, lang, value) => {
		const langToSelect = { ...lang, selected: value.target.checked };
		handleUpdateExpireDate(langToSelect);
	};

	const formatDate = date => {
		const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
		return new Date(date).toLocaleDateString('en-GB', options);
	};
	const renderLanguage = (lang, index) => {
		const file = files.filter(({ language }) => language === lang.lang)[0];
		return (
			<div key={index} className={'prod-spec'}>
				<h3 className={'prod-spec__language'}>{t(`common.${lang.lang}`)}</h3>
				{file && renderFile(file, index, lang)}
				{!file && renderUploadButton(lang, file)}
			</div>
		);
	};

	const renderFile = (file, index, lang) => {
		return (
			<div key={file.url} className={'prod-spec__file'}>
				{!disabled && file.url && (
					<>
						<button onClick={() => handleFileRemove(file.language)} className={'file__remove'}>
							<FontAwesomeIcon icon="times" />
						</button>
					</>
				)}

				{file.url && disabled && !file.uploadDate?.startsWith(TEMPORARILY_STORED) && (
					<>
						<a href={file.url} className={'file__name'} rel="noopener noreferrer" target={'_blank'}>
							<FontAwesomeIcon icon={faFilePdf} />
							{file.name}
						</a>
						<div className={'file__dates'}>
							<span className={'file__dates__upload'}>
								{t('products.product_upload_date')}: {formatDate(file.uploadDate)}
							</span>
							<span className={!file.isAlmostExpired ? 'file__dates__expired' : 'file__dates__expired__sos'}>
								{t('products.product_expired_date')}: {formatDate(file.expiredDate)}
							</span>
						</div>
					</>
				)}
				{file.url && disabled && file.uploadDate?.startsWith(TEMPORARILY_STORED) && (
					<>
						<a href={file.url} className={'file__name'} rel="noopener noreferrer" target={'_blank'}>
							<FontAwesomeIcon icon={faFilePdf} />
							{file.name}
						</a>
						<div className={'file__dates'}>
							<span className="file__dates__expired__sos">{t('technical.temporarily_stored')}</span>
						</div>
					</>
				)}
				{file.url && !disabled && file.isAlmostExpired && (
					<>
						<a href={file.url} className={'file__name'} rel="noopener noreferrer" target={'_blank'}>
							<FontAwesomeIcon icon={faFilePdf} />
							{file.name}
						</a>
						{file.uploadDate && (
							<div className={'file__dates'}>
								<span className={'file__dates__upload'}>
									{t('products.product_upload_date')}: {formatDate(file.uploadDate)}
								</span>
								<span className={!file.isAlmostExpired ? 'file__dates__expired' : 'file__dates__expired__sos'}>
									{t('products.product_expired_date')}: {formatDate(file.expiredDate)}
								</span>
								<Checkbox
									id={`checkbox__refresh_${index}`}
									checked={lang.selected}
									onToggle={value => handleCheckboxChange(index, lang, value)}
									className={'checkbox__refresh'}
								>
									<span className={'checkbox__refresh__text'}>{t('technical.checkbox_update_expired_date_vvodocs')}</span>
								</Checkbox>
							</div>
						)}

						{!file.uploadDate && (
							<div className={'file__dates'}>
								<span className="file__dates__expired__sos">{t('technical.not_yet_saved')}</span>
								<Checkbox
									id={`checkbox__refresh_${index}`}
									checked={lang.selected}
									onToggle={value => handleCheckboxChange(index, lang, value)}
									className={'checkbox__refresh'}
								>
									<span className={'checkbox__refresh__text'}>{t('technical.checkbox_update_expired_date_vvodocs')}</span>
								</Checkbox>
							</div>
						)}
					</>
				)}
				{file.url && !disabled && !file.isAlmostExpired && (
					<>
						<a href={file.url} className={'file__name'} rel="noopener noreferrer" target={'_blank'}>
							<FontAwesomeIcon icon={faFilePdf} />
							{file.name}
						</a>
						{file.uploadDate && !file.uploadDate?.startsWith(TEMPORARILY_STORED) && (
							<div className={'file__dates'}>
								<span className={'file__dates__upload'}>
									{t('products.product_upload_date')}: {formatDate(file.uploadDate)}
								</span>
								<span className={!file.isAlmostExpired ? 'file__dates__expired' : 'file__dates__expired__sos'}>
									{t('products.product_expired_date')}: {formatDate(file.expiredDate)}
								</span>
							</div>
						)}

						{file.uploadDate && file.uploadDate?.startsWith(TEMPORARILY_STORED) && (
							<div className={'file__dates'}>
								<span className="file__dates__expired__sos">{t('technical.temporarily_stored')}</span>
							</div>
						)}

						{!file.uploadDate && (
							<div className={'file__dates'}>
								<span className="file__dates__expired__sos">{t('technical.not_yet_saved')}</span>
							</div>
						)}
					</>
				)}
				{!file.url && <span className={'file__name file__name--no-url'}>{file.name}</span>}
			</div>
		);
	};

	const renderUploadButton = (lang, file) => {
		if (disabled) {
			if (!file) {
				return <div className={'prod-spec__no-file'}>{t('common.no_file')}</div>;
			}
			return null;
		}
		return (
			<div className={'prod-spec__upload'}>
				<FileUpload onDrop={([file]) => handleFileChange(file, lang.lang)} uploading={isSaving.includes(lang.lang)} type={'file'} accept={'.pdf'} />
			</div>
		);
	};

	return (
		<div className={'VvoDocs'}>
			<h2>{t('products.product_vvodocs_title')}</h2>
			<span className={'requirements'}>{t('common.only_pdf_files')}</span>
			<section>
				<div className={'prod-specs'}>{languages.map((lang, index) => renderLanguage(lang, index))}</div>
			</section>
		</div>
	);
};

export default VvoDocs;
