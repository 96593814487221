import React from 'react';
import Submission from './../Submission/Submission';

export default function SubtypeSection(props) {
	const {
		id,
		submissions,
		action,
		removeAction,
		setAction,
		getPhotoLocation,
		addAction
	} = props;

	return (
		<div className={'SubtypeSection'}>
			{submissions.map( (submission, index) => {
				return (
					<>
						<Submission key={index}
							id={id}
							{...submission}

							{...(action?.action === 'delete' && action.saveFailError && index === submissions.length - 1 ? { saveFailError: action.saveFailError }: {})}
							removeAction={removeAction}

							canDelete={index === submissions.length - 1 && !action}
							addAction={addAction}
							deleting={action?.action === 'delete'}
							isLast={index === submissions.length - 1}
							getPhotoLocation={getPhotoLocation}
							hasAction={!!action}
						/>
					</>
				);
			})}
			{action?.action !== 'delete' && <>
				<Submission
					id={id}
					{...action}
					removeAction={() => removeAction(action)}
					setValue={(value) => {
						setAction(action, value);
					}}
					getPhotoLocation={getPhotoLocation}
				/>
			</>
			}
		</div>
	);
}

//
