import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@abm-international/react-components';

export default function ButtonLink(props: any) {
	const buttonProps = {
		...props,
		to: false
	};

	return (
		<Link to={props.to} onClick={(e: any) => {
			if (props.stopPropagation) e.stopPropagation();
		}}>
			<Button {...buttonProps}>
				{props.children}
			</Button>
		</Link>
	);
}
