import store from './../../store';
import { log } from './loggerSlice';

export default class BrowserConsoleTransport {
	constructor(options = {}) {
		this.endpoint = options.endpoint;
		this.name = options.name || 'HttpTransport';
		this.level = options.level || 'info';
	}

	log(level, msg, meta) {
		const data = {
			'fileset.name': level,
			'abm.severity': level,
			'abm.timestamp': Date.now(),
			'abm.application': 'suppliersportal.client',
			'abm.useragent': window.navigator.userAgent,
			'message': `[suppliersportal.client][${level}] ${msg}`,
			'abm.data': JSON.stringify(meta)
		};
		if (!this.endpoint) return;
		store.dispatch(log(data, `${this.endpoint}/log`));
	}
}
