import React, { useEffect, useState } from 'react';
import { t } from './../../../libraries/i18n';
import * as api from './../../../libraries/api';
import { toast } from 'react-toastify';
import { logger } from './../../../libraries/logger/logger';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocale, setLocale } from './../../../slices/userSlice';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

export function PasswordReset() {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	const [password, setPassword] = useState('',);
	const [confirm, setConfirm] = useState('');
	const [errors, setErrors] = useState([]);
	const [token, setToken] = useState(null);

	const [isNewUser, setIsNewUser] = useState(false);
	const locale = useSelector(selectLocale);

	const queryParameter = location.search;

	useEffect(() => {
		if (queryParameter) {
			const searchParams = new URLSearchParams(queryParameter);
			const urlLocale = searchParams.get('language');
			dispatch(setLocale({ locale: urlLocale }));

			const urlToken = searchParams.get('token');
			if (!urlToken) setErrors(['auth.bad_link']);
			else setToken(urlToken);

			setIsNewUser(searchParams.has('new'));
		}
	}, [queryParameter, dispatch]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		let error;

		if (!password) {
			error = 'auth.no_password_message';
		} else if (password !== confirm) {
			error = 'auth.passwords_do_not_match';
		}

		const body = {
			token,
			password,
			language: locale
		};

		if (errors?.length || error) return;

		try {
	        await api.post('/set', JSON.stringify(body));
	        history.push('/auth/login');
		} catch (e) {

	        if (e.code === 1001) {
	        	setErrors([{ message: t('auth.invalid_token') }]);
		        toast.error(t('auth.invalid_token'));
		        logger.error('Password reset invalid token ERROR', { res: e });
	        } else {
		        toast.error(`Error: ${e.message || e.result.statusText}`);
		        logger.error('Password reset ERROR', { res: e });
	        }
		}
	};

	if (!token) return <h2>{t('auth.no_token')}</h2>;

	const isSubmitDisabled = () => !password || !confirm || password !== confirm || !token;

	const renderErrors = () => (
		<ul className={'form__errors'}>
			{errors.map((error, key) => (
				<li key={key}>
					{t(error.message)}
				</li>
			))}
		</ul>
	);

	return (
		<>
			<h1>{t(isNewUser ? 'auth.set_password' : 'auth.reset_password')}</h1>

			<form className={'form'} onSubmit={handleSubmit}>
				<input
					type='password'
					value={password}
					onChange={({ target }) => setPassword(target.value)}
					placeholder={t('auth.password')}
				/>
				<input
					type='password'
					value={confirm}
					onChange={({ target }) => setConfirm(target.value)}
					placeholder={t('auth.password_confirm')}
				/>
				{errors && renderErrors()}
				<button className={'button button--alt'} disabled={isSubmitDisabled()}>
					{t('auth.action_reset')}
				</button>
			</form>
			<Link className={'link_to'} to={'/auth/forgot'}>{t('auth.forgot_password')}</Link>
		</>
	);

}

export default PasswordReset;
