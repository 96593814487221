import './ListFilter.scss';
import React from 'react';
import Search from './../Search/Search';
import { t } from './../../libraries/i18n';
import { Button } from '@abm-international/react-components';

export default function ListFilter(props) {
	const {
		query,
		setQuery,
		filters,
		filter,
		setFilter,
		placeholder
	} = props;
	const showFilteredAmount = query !== '';
	return (
		<div className={'ListFilter'}>
			<Search
				query={query}
				handleSearch={({ target }) => setQuery(target.value)}
				placeholder={placeholder ?? t('commercial.search_placeholder')}
			/>
			{filters && (
				<div className={'checkbox-group'}>
					{filters.map( ({ value, amount, filtered }, index) => (
						<Button
							key={index}
							className={`filter${(value === filter) ? ' selected' : ''}`}
							onClick={() => setFilter(value)}
							borderOnly={(value !== filter)}
						>
							{`${t(`products.filter_state_${value?.toLowerCase?.() ?? value ?? 'all'}`)} (${showFilteredAmount ? `${filtered.length}/${amount}` : amount})`}
						</Button>
					))}
				</div>
			)}

		</div>
	);
}
