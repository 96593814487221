import useSWR from 'swr';
import { fetcher, transformProduct } from './index';
import { Product, ProductResponse } from '../interfaces';
import { TechnicalStatus } from '../interfaces/technical';

const endpoint = 'suppliers';
const noAutoRevalidateOptions = {
	revalidateOnFocus: false,
	revalidateOnReconnect: false,
	refreshWhenOffline: false,
	refreshWhenHidden: false,
	refreshInterval: 0
};

const transformAllergens = (data: any) => {
	if (!data) return undefined;
	return Array.isArray(data.allergens) ? {} : data.allergens;
};

const transformSpecifications = (data: any) => {
	if (!data) return undefined;
	return data.specifications;
};

const transformVvoDocs = (data: any) => {
	if (!data) return undefined;
	return data?.vvodocs ?? [];
};

const transformAllergenDefinitions = (data: any) => {
	if (!data) return undefined;
	return data.allergenDefinitions ?? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
};

export function useProduct(supplierId: string, productId: string) {
	const { data, error, mutate } = useSWR(`/${endpoint}/${supplierId}/articles/${productId}`, fetcher, noAutoRevalidateOptions);
	return {
		product: transformProduct(data),
		status: getStatus(data?.technical.isNew, data?.technical.isApproved, data?.technical.isAlmostExpired),
		allergens: transformAllergens(data),
		specifications: transformSpecifications(data),
		vvoDocs: transformVvoDocs(data),
		allergenDefinitions: transformAllergenDefinitions(data),
		isLoading: !error && !data,
		error,
		mutate: (newData: Record<string, any>, revalidate: boolean) => {
			if (revalidate) {
				return mutate({ ...data, ...newData }, true);
			}
			// don't mutate if result is the same
			if (JSON.stringify(data) !== JSON.stringify({ ...data, ...newData })) {
				mutate({ ...data, ...newData }, false);
			}
		}
	};
}

const getStatus = (isNew: boolean | undefined, isApproved: boolean | undefined, isAlmostExpired: boolean | undefined): TechnicalStatus | undefined => {
	if (isNew === undefined && isApproved === undefined) return undefined;
	if (isNew) return TechnicalStatus.NEW;
	if (isAlmostExpired) return TechnicalStatus.ALMOST_EXPIRED;
	if (isApproved) return TechnicalStatus.APPROVED;
	return TechnicalStatus.UNAPPROVED;
};

const transformProducts = (products: Array<ProductResponse>): Array<Product> => {
	if (!products) return [];
	return products.map(({
		isNew, isApproved, isAlmostExpired, ...data
	}) => ({
		...data,
		technical: {
			status: getStatus(isNew, isApproved, isAlmostExpired)
		}
	}));
};

export function useProducts(supplierId: string) {
	const { data, error, mutate } = useSWR(`/${endpoint}/${supplierId}/articles`, fetcher);

	return {
		products: transformProducts(data),
		isLoading: !error && !data,
		error,
		mutate
	};
}
