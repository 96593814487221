import React, { useEffect, useState } from 'react';
import './Sublogin.scss';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import {
	DynamicForm,
	SuppliersInput,
	Button
} from '@abm-international/react-components';
import { useSelector } from 'react-redux';
import {
	useSublogin,
	updateSublogin,
	disableSublogin,
	deleteSublogin,
} from './../../api/sublogins';
import { t } from './../../libraries/i18n';
import useMetadata from './../../api/useMetadata';
import { Account, Associate } from './../../interfaces/settings/sublogin';
import { selectAccountId } from './../../slices/userSlice';
import ButtonLink from '../../common/ButtonLink/ButtonLink';
import { ReactComponent as Arrow } from './../../assets/arrow.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faBan,
	faCheck,
	faExclamationCircle,
	faPencilAlt,
	faTimes,
	faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import getPermissionsTranslation from './../../libraries/getPermissionsTranslation';
import PortalModal from './../../common/PortalModal/PortalModal';

export default function SubloginEdit() {
	const accountId = useSelector(selectAccountId);
	const { id } = useParams<{ id: string }>();
	const match = useRouteMatch();
	const isEditing = match.path.endsWith('edit');

	const { sublogin, mutate } = useSublogin(accountId, id);
	const [submitError, setSubmitError] = useState<any>(false);
	const {
		permissions,
		groupedPermissions,
		suppliers,
		error: metadataError
	} = useMetadata(accountId);
	const [newSublogin, setNewSublogin] = useState<any>(false);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const history = useHistory();
	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
	const [hasChanges, setHasChanges] = useState(false);

	useEffect(() => {
	 	if (hasChanges && JSON.stringify(newSublogin) === JSON.stringify(sublogin)) {
			 setHasChanges(false);
		}
	}, [newSublogin, sublogin, hasChanges]);

	useEffect(() => {
		if (!hasChanges && !newSublogin) setNewSublogin(sublogin);
	}, [hasChanges, newSublogin, sublogin]);

	const accountFields = [
		{
			title: t('sublogins.title_email'),
			name: 'email',
			type: 'email',
			placeholder: t('sublogins.placeholder_email'),
			disabled: true
		},
		{
			title: t('sublogins.title_name'),
			name: 'name',
			type: 'text',
			placeholder: t('sublogins.placeholder_name'),
			disabled: !isEditing
		},
		{
			title: t('sublogins.title_language'),
			name: 'language',
			type: 'select',
			disabled: !isEditing,
			options: {
				choices: [
					{
						title: t('common.nl'),
						value: 'nl'
					},
					{
						title: t('common.fr'),
						value: 'fr'
					},
					{
						title: t('common.de'),
						value: 'de'
					},
					{
						title: t('common.en'),
						value: 'en'
					}
				]
			}
		}
	];

	const accountSections = [
		{
			title: t('sublogins.title_section_main'),
			fields: [
				'email',
				'name',
				'language'
			]
		},
	];

	const getSuppliersById = () => {
		const byId: Record<string, Associate> = {};
		suppliers?.forEach((supplier: Associate) => byId[supplier.id.toString()] = supplier);
		return byId;
	};

	const suppliersInputField = {
		title: t('sublogins.title_suppliers'),
		name: 'associates',
		type: 'custom',
		placeholder: t('sublogins.placeholder_suppliers'),
		disabled: !isEditing,
		options: {
			component: SuppliersInput,
			noLabel: true,
			customProps: {
				suppliers,
				suppliersById: getSuppliersById()
			}
		}
	};

	const permissionField = {
		title: t('sublogins.title_permissions'),
		name: 'permissions',
		type: 'permissions',
		disabled: !isEditing,
		options: {
			noLabel: true,
			usePermissions: () => ({
				permissions,
				groupedPermissions,
			}),
			t: getPermissionsTranslation
		}
	};

	const getFields = () => {
		let fields: any = [...accountFields];
		if (suppliers && suppliers.length > 1) fields = [...fields, suppliersInputField];

		return [
			...fields,
			permissionField
		];
	};

	const getSections = () => {
		let sections: any = [...accountSections];
		if (suppliers && suppliers.length > 1) {
			sections = [
				...sections,
				{
					title: t('sublogins.title_section_suppliers'),
					fields: [
						'associates'
					]
				}
			];
		}

		return [
			...sections,
			{
				title: t('sublogins.title_section_permissions'),
				fields: [
					'permissions'
				]
			}
		];
	};

	const submit = async () => {
		setIsSubmitting(true);
		setSubmitError(false);

		try {
			await updateSublogin(accountId, id, newSublogin);
		} catch (error) {
			setSubmitError(error);
			setIsSubmitting(false);
			return;
		}

		mutate();
		setIsSubmitting(false);
		history.push('/sublogins');
	};

	const confirmDelete = async () => {
		await deleteSublogin(accountId, id);
		history.push('/sublogins');
	};

	const renderActions = () => {
		if (!isEditing) {
			return (
				<ButtonLink to={`/sublogins/${id}/edit`}>
					<FontAwesomeIcon icon={faPencilAlt} />
					{t('sublogins.button_edit')}
				</ButtonLink>
			);
		}

		return (
			<div className='sublogin__actions'>
				<Button
					disabled={!hasChanges || isSubmitting}
					onClick={submit}
				>
					<FontAwesomeIcon icon={faCheck} />
					{t('sublogins.button_save')}
				</Button>
				<Button
					onClick={async () => {
						await disableSublogin(accountId, id, sublogin);
						mutate();
						history.push('/sublogins');
					}}
					textOnly
					noUnderline
					className='orange'
					disabled={hasChanges}
				>
					<FontAwesomeIcon icon={faBan} />
					{t(sublogin?.loginEnabled ? 'sublogins.button_disable_sublogin' : 'sublogins.button_enable_sublogin')}
				</Button>
				<Button
					onClick={() => setShowDeleteConfirmation(true)}
					textOnly
					noUnderline
					className='red'
					disabled={hasChanges}
				>
					<FontAwesomeIcon icon={faTrashAlt} />
					{t('sublogins.button_delete')}
				</Button>
				<ButtonLink
					to={`/sublogins/${id}`}
					textOnly
					noUnderline
				>
					<FontAwesomeIcon icon={faTimes} />
					{t('sublogins.button_cancel')}
				</ButtonLink>
			</div>
		);
	};


	const renderSubmitError = () => (
		<div className='submit__error'>
			<FontAwesomeIcon icon={faExclamationCircle} />
			{/* eslint-disable-next-line */}
			{submitError?.response?.data?.error_message}
		</div>
	);

	const renderMetadataError = () => (
		<div className='error'>
			<FontAwesomeIcon icon={faExclamationCircle} />
			{t('accounts.label_loading_metadata_failed')}
		</div>
	);

	return (
		<div className='Sublogin SubloginEdit'>
			<ButtonLink to={'/sublogins'} textOnly noUnderline className='back'>
				<Arrow className={'arrow arrow-left'} />
				{t(hasChanges ? 'sublogins.button_to_overview_cancel' : 'sublogins.button_to_overview')}
			</ButtonLink>
			<h1>{t('sublogins.title_edit')}</h1>

			{metadataError && renderMetadataError()}

			{permissions && groupedPermissions && sublogin &&
				<>
					<DynamicForm
						fields={getFields()}
						values={newSublogin}
						sections={getSections()}
						onChange={(data: Account) => {
							setNewSublogin(data);
							setHasChanges(true);
						}}
					/>
					{submitError && renderSubmitError()}
					{renderActions()}
				</>
			}

			{showDeleteConfirmation &&
				<PortalModal className='sublogin__confirmation' close={() => setShowDeleteConfirmation(false)}>
					<div className='delete_modal'>
						<div className='modal__text'>
							{t('sublogins.label_delete_confirmation')}
						</div>
						<div className='actions'>
							<Button
								className={'red'}
								textOnly
								noUnderline
								onClick={confirmDelete}
								icon={<FontAwesomeIcon icon={faTrashAlt} />}
							>
								{t('sublogins.button_confirm_delete')}
							</Button>
							<Button
								textOnly
								noUnderline
								onClick={() => setShowDeleteConfirmation(false)}
								iconLeft={<FontAwesomeIcon icon={faTimes} />}
							>
								{t('sublogins.button_cancel_delete')}
							</Button>
						</div>
					</div>
				</PortalModal>
			}
		</div>
	);
}
