import React, { useState, useEffect } from 'react';
import './Commercial.scss';
import { useSelector } from 'react-redux';
import { getLocale, t } from './../../libraries/i18n';
import { useHistory } from 'react-router';
import { List, Button } from '@abm-international/react-components';
import useSupplierRoute from './../../libraries/useSupplierRoute';
import { Product } from './../../interfaces';
import { filterItemsByQuery, rankItems, sortItems } from '../../libraries/filter';
import { ProductCommercialData, SubmissionStatus } from '../../interfaces/commercial';
import { selectSupplierId } from '../../slices/suppliersSlice';
import { useProducts } from './../../api/commercial';
import ListFilters from '../../common/ListFilter/ListFilter';
import { SafeImg } from '../../common';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Commercial() {
	useSupplierRoute();
	const history = useHistory();
	const supplierId = useSelector(selectSupplierId);
	const { products, isLoading, error } = useProducts(supplierId);
	const [query, setQuery] = useState('');
	const [filter, setFilter] = useState<number | null>(null);
	const [sortKey, setSortKey] = useState('orderReference');
	const [sortDirection, setSortDirection] = useState(true);
	const [page, setPage] = useState(0);


	const { height } = useWindowDimensions();
	// each item is 70px, the header is 70px and the footer is 60px
	const itemsPerPage = Math.floor(height / 70 - 2);

	useEffect(() => {
		setPage(0);
	}, [query, filter, sortKey, sortDirection, itemsPerPage]);

	useEffect(() => {
		const savedFilters = sessionStorage.getItem('commercialFilters');
		if (savedFilters){
			const {
				filter,
				query,
				sortDirection,
				sortKey
			} = JSON.parse(savedFilters);

			setQuery(query);
			setFilter(filter);
			setSortDirection(sortDirection);
			setSortKey(sortKey);
		}

	}, []);
	useEffect(() => {
		const commercialFilters = {
			filter,
			query,
			sortDirection,
			sortKey
		};

		sessionStorage.setItem('commercialFilters', JSON.stringify(commercialFilters));
	}, [filter, query, sortDirection, sortKey]);

	document.title = `${t('commercial.title')} | Charles Supplier Portal`;

	const countTranslation = (amount: number) => {
		switch (amount) {
			case 0:
				return 'label_no_items';
			case 1:
				return 'label_one_item';
			default:
				return 'label_n_items';
		}
	};

	const renderHeader = ({ key, title }: {key: 'ref' | 'codeS' | 'codeW' | 'name', title: string}) => {
		const keyToSortKeyMap = {
			ref: 'orderReference',
			codeS: 'article.S.code',
			codeW: 'article.W.code',
			name: `article.S.description.${getLocale()}`
		};

		const sk = keyToSortKeyMap[key];
		const icon = sortKey === sk ? sortDirection ? faSortUp : faSortDown : faSort;
		return (
			<span
				className={'header_item'}
				onClick={() => {
					if (sortKey !== sk){
						setSortKey(sk);
						setSortDirection(true);
					} else {
						setSortDirection(!sortDirection);
					}
				}}

			>
				{title}
				<FontAwesomeIcon icon={icon} />
			</span>
		);
	};

	const renderSiteHeader = ({ title }: {title: string}) => {

		return (
			<div data-group={title} className={'header_group'}>
				{renderHeader({ key: 'codeS', title: 'st. nik' })}
				{renderHeader({ key: 'codeW', title: 'womm' })}
			</div>

		);
	};

	const listHeader = [
		{
			key: 'img',
			title: null,
			render: ({ images }: Product) => <SafeImg src={images.small} className={'thumbnail'} alt={''} />
		},
		{
			key: 'ref',
			title: t('products.product_ref'),
			render: ({ orderReference }: Product) => orderReference,
			renderHeader
		},
		{
			key: 'code',
			title: t('products.product_code'),
			render: ({ article }: Product) => (
				<>
					<span className={'siteCode S'}>{article.S.code}</span>
					<span className={'siteCode W'}>{article.W.code}</span>
				</>
			),
			renderHeader: renderSiteHeader
		},
		{
			key: 'name',
			title: t('products.product_name'),
			render: ({ article }: Product) => article?.S?.description?.[getLocale()],
			renderHeader
		},
		{
			key: 'status',
			title: null,
			render: ({ commercial }: Product) => (
				<Button>
					<span className={`circle status--${commercial?.status}`} />
					<span className={'text--status'}>{t(`commercial.button_status_${commercial?.status}`)}</span>
				</Button>
			)
		}
	];

	const filters: Array<{value: string | null, amount: number, filtered: Product[]}> = [
		{
			value: null,
			amount: products?.length,
			filtered: []
		},
		{
			value: 'view',
			amount: products.filter(({ commercial }: Record<'commercial', ProductCommercialData>) => commercial.status === SubmissionStatus.APPROVED).length,
			filtered: []
		},
		{
			value: 'action',
			amount: products.filter(({ commercial }: Record<'commercial', ProductCommercialData>) => [SubmissionStatus.NEW, SubmissionStatus.REJECTED].includes(commercial.status)).length,
			filtered: []
		},

	];

	const getFilteredProducts = (products: Array<Product>): Array<Product> | undefined => {
		if (isLoading) return;
		if (!products) return [];

		let filteredProducts = products.sort((a, b) => sortItems(a, b, sortDirection ? 'ascending' : 'descending', sortKey));

		if (query) {
			// @ts-ignore
			filteredProducts = filterItemsByQuery(query, filteredProducts, [`article.S.description.${getLocale()}`, 'article.S.code', 'article.W.code', 'orderReference'], true, 'AND');
			filteredProducts = filteredProducts.sort((a, b) => rankItems(a, b, sortDirection ? 'ascending' : 'descending', sortKey));
		}

		filters[0].filtered = filteredProducts;
		filters[1].filtered = filteredProducts.filter(({ commercial }) => commercial.status === SubmissionStatus.APPROVED);
		filters[2].filtered = filteredProducts.filter(({ commercial }) => [SubmissionStatus.NEW, SubmissionStatus.REJECTED].includes(commercial.status));

		return filters.find(({ value }) => value === filter)?.filtered ?? [];
	};

	const filteredProducts = getFilteredProducts(products)?.map(product => ({ id: product.article.S.code, ...product }));

	return (
		<div className={'Commercial'}>
			<h2>{t('commercial.title')}</h2>
			{!isLoading && <h3>{t(`products.${countTranslation(products?.length ?? 0)}`, products?.length ?? 0)}</h3>}
			{isLoading && <h3>loading ...</h3>}
			<ListFilters
				query={query}
				setQuery={setQuery}
				filters={filters}
				filter={filter}
				setFilter={setFilter}
			/>



			{error && (
				<div className={'message'}>
					<span role='img' aria-label='error emoji'>💥</span>
					{t('commercial.label_error_loading_overview')}
				</div>
			)}

			<List
				header={listHeader}
				content={filteredProducts}
				action={(_: any, { article }: Product) => {
					history.push(`commercial/${article.id}`);
				}}
				gridTemplateColumns={'8rem 14rem 19rem 1fr 14rem'}

				pagination={{
					itemsPerPage,
					page,
					setPage
				}}
			/>

			{!isLoading && filteredProducts?.length === 0 && (
				<div className={'message'}>
					{t('commercial.label_no_products_found')}
				</div>
			)}


		</div>
	);
}
