import React from 'react';
import { t } from './../../libraries/i18n';
import useMe from './../../api/useMe';
import { matchPath, useLocation } from 'react-router';

const BLACKLIST = [
	'/products/commercial/:id',
	'/products/technical/:id',
];

export default function VersionAlert() {
	const location = useLocation();
	const { me } = useMe();
	const latestClientVersion = me?.clients?.find(c => c.application === process.env.REACT_APP_APP_NAME)?.version ?? null;
	const currentClientVersion = process.env.REACT_APP_APP_VERSION;

	const reload = () => document.location.reload();

	const matchesBlacklistPath = location => BLACKLIST.some(blacklistedPath => matchPath(location, { path: blacklistedPath }));

	if (matchesBlacklistPath(location.pathname)) return null;

	if (currentClientVersion >= latestClientVersion) return null;

	return (
		<div className='VersionAlert Alert'>
			{t('common.label_new_version_available')}
			<button className='link' onClick={reload}>
				{t('common.button_refresh_to_update_app')}
			</button>
		</div>
	);
}
