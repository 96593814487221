import React, { useEffect, useState } from 'react';
import './suppliers.scss';
import { filterItemsByQuery } from './../../libraries/filter';
import { ListFilter } from './../../common';
import { List } from '@abm-international/react-components';
import { t } from './../../libraries/i18n';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchSuppliersIfNeeded,
	resetSupplier, selectSuppliers,
	setSupplier
} from './../../slices/suppliersSlice';
import { useHistory } from 'react-router';
import useSearchParams from './../../libraries/useSearchParams';
import useWindowDimensions from '../../hooks/useWindowDimensions';


export default function Suppliers() {
	const dispatch = useDispatch();
	const [query, setQuery] = useState('');
	const { from } = useSearchParams();
	const history = useHistory();
	const [page, setPage] = useState(0);
	const { height } = useWindowDimensions();
	// each item is 70px, the header is 70px and the footer is 60px
	const itemsPerPage = Math.floor(height / 55.5 - 2);

	useEffect(() => {
		setPage(0);
	}, [query, itemsPerPage]);


	const suppliersObj = useSelector(selectSuppliers);
	const suppliers = suppliersObj ? Object.values(suppliersObj) : undefined;

	useEffect(() => {
		dispatch(resetSupplier());
	}, [dispatch]);

	useEffect(() => {
		dispatch(fetchSuppliersIfNeeded());

		if (suppliers?.length === 1) {
			dispatch(setSupplier({ code: suppliers[0].code }, () => history.push(from ?? '/')));
		}
	}, [dispatch, from, history, suppliers]);

	document.title = `${t('common.app_title')} | Charles Supplier Portal`;

	const filteredSuppliers = filterItemsByQuery(query, suppliers, ['name', 'code'], true, 'AND')
		?.map(({ code, name }) => ({ id: code, code, name }));

	const listHeader = [
		{
			key: 'code',
			title: t('suppliers.label_code'),
			render: ({ code }) => code
		},
		{
			key: 'name',
			title: t('suppliers.label_name'),
			render: ({ name }) => name
		}
	];

	return (
		<div className="Suppliers">
			<h2>{t('common.app_title')}</h2>
			<ListFilter
				query={query}
				setQuery={setQuery}
				placeholder={t('suppliers.searchPlaceholder')}
			/>
			<List
				header={listHeader}
				content={filteredSuppliers}
				action={(_, { code }) => dispatch(setSupplier({ code }, () => history.push(from ?? '/')))}
				pagination={{
					itemsPerPage,
					page,
					setPage
				}}
			/>
		</div>
	);
}
