
export enum TechnicalStatus {
	NEW = 'NEW',
	UNAPPROVED = 'UNAPPROVED',
	APPROVED = 'APPROVED',
	ALMOST_EXPIRED = 'ALMOST_EXPIRED'
}

export interface ProductTechnicalData {
	status?: TechnicalStatus,
	allergenDefinitions?: Array<number | string>,
	allergens?: Array<any>,
	specifications?: Array<Record<string, any>>
}
