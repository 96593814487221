import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import useSupplierRoute from '../../libraries/useSupplierRoute';
//import SubloginView from './SubloginView';
import SubloginEdit from './SubloginEdit';
import SubloginNew from './SubloginNew';

export default function Sublogin() {
	useSupplierRoute();
	const match = useRouteMatch();

	return (
		<Switch>
			<Route exact path={`${match.path}/new`} render={() => <SubloginNew />} />
			<Route exact path={`${match.path}/:id`} component={SubloginEdit} />
			<Route exact path={`${match.path}/:id/edit`} render={() => <SubloginEdit />} />
		</Switch>
	);
}
