import React from 'react';
import './Alerts.scss';
import VersionAlert from './VersionAlert';

export default function Alerts() {
	return (
		<div className='Alerts'>
			<VersionAlert />
		</div>
	);
}
