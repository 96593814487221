import { combineReducers } from '@reduxjs/toolkit';
import suppliersReducer from './slices/suppliersSlice';
import userReducer from './slices/userSlice';
import errorReducer from './slices/errorSlice';
import { loggerSlice } from './libraries/logger/loggerSlice';

const rootReducer = combineReducers({
	suppliers: suppliersReducer,
	user: userReducer,
	log: loggerSlice,
	error: errorReducer
});

export default rootReducer;
