import React, { useEffect, useState } from 'react';
import './allergens.scss';
import { t } from '../../../../libraries/i18n';
import { Checkbox, Spinner } from '@abm-international/react-components';

export default function Allergens({
	definitions,
	allergens,
	disabled,
	setAllergens
}) {
	const [noAllergens, setNoAllergens] = useState(false);
	const [savedAllergens, setSavedAllergens] = useState({});

	useEffect(() => {
		if (definitions){
			const allFalse = definitions.every(id => allergens[id] === false);

			if (!noAllergens) {
				const noneTrue = Object.values(allergens).every(value => value === false);

				if (allFalse) {
					setNoAllergens(true);
				} else if (noneTrue) {
					setAllergens({});
				} else {
					setAllergens(Object.fromEntries(Object.entries(allergens).filter(([key, value]) => value)));
				}
			} else {
				if (!allFalse){
					setNoAllergens(false);
				}
			}
		}
	}, [definitions, allergens, noAllergens, setAllergens, setNoAllergens, savedAllergens]);

	const toIdString = (id) => id.toString()
		.replace(' ', '-')
		.replace('.', '_');

	return (
		<div className={`Allergens${ disabled ? ' disabled' : ''}`}>
			<h2>{t('allergens.allergens_title')}</h2>
			<section>
				{!definitions && <Spinner />}
				{definitions && (
					<>
						<Checkbox
							id={'no-allergens'}
							className={`no-allergens ${noAllergens ? ' checked' : ''}`}
							checked={noAllergens}
							onToggle={() => {
								if (noAllergens) {
									setAllergens(savedAllergens);
								} else {
									setSavedAllergens(allergens);
									setAllergens(Object.fromEntries(definitions.map(id => [id, false])));
								}
							}}
							display={disabled}
						>
							{t('allergens.no_allergens')}
						</Checkbox>
						<div className={'allergens'}>
							{definitions.map(id => (
								<Checkbox
									className={allergens[id] ?? false ? 'checked' : ''}
									key={toIdString(id)}
									id={toIdString(`allergen__${ id}`)}
									checked={noAllergens ? false : allergens[id] ?? false}
									onToggle={() => {
										if (!noAllergens) setAllergens({ ...allergens, [id]: !allergens[id] });
									}}
									disabled={noAllergens && !disabled}
									display={disabled}
								>
									{t(`allergens.allergens_${toIdString(id)}`)}
								</Checkbox>
							))}
						</div>
					</>
				)}
			</section>
		</div>
	);

}

