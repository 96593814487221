import React, { useState } from 'react';
import './SubmissionSection.scss';
import ArchiveLink from './../ArchiveLink/ArchiveLink';
import SubtypeSection from './../SubtypeSection/SubtypeSection';
import { t } from './../../../libraries/i18n';
import { Button, Checkbox, } from '@abm-international/react-components';
import {
	Submission as ISubmission,
	SubmissionCategory,
	SubmissionStatus,
	SubmissionType,
	TypeAmounts
} from './../../../interfaces/commercial';
import Submission from './../Submission/Submission';
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { selectHasPermission } from '../../../slices/userSlice';

interface Props {
	id: string,
	submissions: Array<ISubmission>,
	actions: Array<ISubmission>
	category: SubmissionCategory,
	type: SubmissionType,
	amounts: TypeAmounts,
	addAction: (submission: ISubmission) => void,
	removeAction: (submission: ISubmission) => void,
	setAction: (submission: ISubmission, value: string, letCharlesDoIt?: boolean) => void,
	getPhotoLocation: (type: SubmissionType, url: string) => string,
	letCharlesDoIt?: boolean | undefined
}

export default function SubmissionSection(props: Props) {
	const {
		id,
		submissions,
		actions,
		category,
		type,
		amounts,
		addAction,
		removeAction,
		setAction,
		getPhotoLocation
	} = props;

	const getSubmissionsThatShouldBeDisplayed = (submissions: Array<ISubmission>): Array<ISubmission> => {
		const filteredSubtypeList = [];
		const sorted = submissions.sort((a: ISubmission, b: ISubmission) => b.version - a.version);

		const lastApprovedOrRemoved = sorted.find((submission: ISubmission) => submission.status === SubmissionStatus.APPROVED || submission.status === SubmissionStatus.REMOVED);
		if (lastApprovedOrRemoved?.status === SubmissionStatus.APPROVED) filteredSubtypeList.push(lastApprovedOrRemoved);

		const lastPendingOrRejected = sorted.find((submission: ISubmission) => submission.status === SubmissionStatus.SUBMITTED || submission.status === SubmissionStatus.REJECTED);
		if (lastPendingOrRejected && lastPendingOrRejected.version > (lastApprovedOrRemoved?.version ?? 0)) filteredSubtypeList.push(lastPendingOrRejected);

		return filteredSubtypeList;
	};

	const [checkedLetCharlesDoIt, setCheckedLetCharlesDoIt] = useState<boolean>(false);

	const onAddAction = (submission: ISubmission) => {
		setCheckedLetCharlesDoIt(false);
		addAction(submission);
	};

	const hasPermission = useSelector(state => selectHasPermission(state, 6022));


	const renderOneSubtype = (action: ISubmission | undefined, submissions: Array<ISubmission>) => {

		const lastSubmission = submissions.reverse()[0];
		const highestVersion = lastSubmission?.version ?? 0;
		const newActionValue = category === SubmissionCategory.TEXT ? lastSubmission?.value ?? '' : '';
		const newActionAction = !lastSubmission ? 'create' : 'update';
		const newAction = {
			category,
			type,
			version: highestVersion,
			status: SubmissionStatus.NEW,
			value: newActionValue,
			subtype: submissions[0]?.subtype ?? 0,
			action: newActionAction,
			letCharlesDoIt: false
		};

		const getAddButtonContent = () => {
			if (submissions.length === 0) {
				return (
					<>
						<FontAwesomeIcon icon={faPlus} />
						{t(`commercial.button_submission_new_initial_${category}`)}
					</>
				);
			}
			return (
				<>
					<FontAwesomeIcon icon={faPen} />
					{t(`commercial.button_submission_new_${category}`)}
				</>
			);

		};

		return (
			<>
				{submissions.map((submission: ISubmission) => {
					return <Submission
						id={id}
						key={submission.version}
						{...submission}
						hasAction={!!action}
						getPhotoLocation={(url: string) => getPhotoLocation(type, url)}
						disableInput={true}
					/>;
				})}

				{action && <>
					<Checkbox id={id + type + category}
						checked={checkedLetCharlesDoIt}
						onToggle={() => {
							setAction(action, action.value, !checkedLetCharlesDoIt);
							setCheckedLetCharlesDoIt((oldValue) => !oldValue);
						}}
						inverted={false}
						disabled={false}
						display={false}
					>{t(`commercial.letCharlesDoIt_${category}`)}</Checkbox>

					<Submission
						id={id}
						{...action}
						removeAction={() => {
							setCheckedLetCharlesDoIt(lastSubmission?.letCharlesDoIt || false);
							removeAction(action);
						}}
						setValue={(value: string) => {
							setAction(action, value);
						}}
						getPhotoLocation={(url: string) => getPhotoLocation(type, url)}
						disableInput={checkedLetCharlesDoIt}
					/>
				</>}
				{!action && (
					<Button onClick={() => onAddAction(newAction)} textOnly noUnderline>
						{getAddButtonContent()}
					</Button>
				)}
			</>
		);
	};

	const renderNSubtypes = (subtypes: Array<number>, actions: Record<number, ISubmission | undefined>, submissions: Record<number, Array<ISubmission>>) => {

		const canAddSubtype = subtypes.length < amounts.max;
		const getAddSubtypeButton = () => {
			if (!canAddSubtype) return null;
			return (
				<Button onClick={() => onAddAction(newSubtypeAction)} textOnly noUnderline icon={<FontAwesomeIcon icon={faPlus} />}>
					{t('commercial.button_submission_new_initial_images')}
				</Button>
			);
		};
		let nextUnusedSubtype = 1;
		for (; subtypes.includes(nextUnusedSubtype); nextUnusedSubtype++) { }
		const newSubtypeAction = {
			subtype: nextUnusedSubtype,
			type,
			category,
			action: 'create',
			version: 0,
			status: SubmissionStatus.NEW,
			value: '',
			letCharlesDoIt: false
		};

		const renderSubtype = (subtype: number, action: ISubmission | undefined, submissions: Array<ISubmission>) => {
			const lastSubmission = [...submissions].reverse()[0];
			const highestVersion = lastSubmission?.version ?? 0;
			const newAction = {
				subtype: subtype,
				value: '',
				action: 'delete',
				status: SubmissionStatus.NEW,
				category,
				type,
				version: highestVersion,
				letCharlesDoIt: false
			};

			return (
				<SubtypeSection
					id={id}
					key={subtype}
					submissions={submissions}
					action={action}
					category={category}
					removeAction={() => action ? removeAction(action) : undefined}
					setAction={setAction}
					addAction={() => onAddAction(newAction)}
					getPhotoLocation={(url: string) => getPhotoLocation(type, url)}
				/>
			);
		};
		return (
			<>
				{subtypes.map((subtype) => renderSubtype(subtype, actions[subtype], submissions[subtype]))}
				{getAddSubtypeButton()}
			</>
		);
	};



	const renderSubtypes = () => {
		if (amounts.max === 1) {
			const filteredSubmissions = getSubmissionsThatShouldBeDisplayed(submissions);
			return renderOneSubtype(actions[0], filteredSubmissions);
		}

		const subtypes = [...new Set([...submissions.map(s => s.subtype), ...actions.map(a => a.subtype)])];
		const filteredSubmissions: Record<number, Array<ISubmission>> = subtypes
			.reduce((collection: Record<number, Array<ISubmission>>, subtype: number) => {
				return {
					...collection,
					[subtype]: getSubmissionsThatShouldBeDisplayed(submissions.filter(s => s.subtype === subtype))
				};
			}, {});
		const filteredActions: Record<number, ISubmission | undefined> = subtypes
			.reduce((collection: Record<number, ISubmission | undefined>, subtype: number) => {
				return {
					...collection,
					[subtype]: actions.find(a => a.subtype === subtype)
				};
			}, {});
		return renderNSubtypes(subtypes, filteredActions, filteredSubmissions);
	};




	return (
		<div className={'SubmissionSection'}>
			<div className={'typeHeader'}>
				<h3>
					{t(`commercial.title_${category}_${type}`)}
					{submissions.length > 0 && hasPermission && <ArchiveLink id={id} category={category} type={type} />}
				</h3>
			</div>

			{renderSubtypes()}
		</div>
	);
}
