import React, { useEffect, useState } from 'react';
import './App.scss';
import { config } from './config';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import {
	Route,
	Redirect,
	Switch
} from 'react-router';
import Technical from './modules/Technical/Technical';
import TechnicalDetail from './modules/Technical/TechnicalDetail/TechnicalDetail';
import Commercial from './modules/Commercial/Commercial';
import Terms from './modules/Terms/Terms';
import CommercialDetail from './modules/Commercial/CommercialDetail/CommercialDetail';
import Suppliers from './modules/suppliers/suppliers';
import {
	Navigation,
	Button,
	SplashScreen,
	LanguageSelector
} from '@abm-international/react-components';

import { t } from './libraries/i18n';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from 'react-redux';
import {
	fetchUserIfNeeded,
	selectAuthenticateErrors,
	selectIsAuthenticating,
	selectLocale,
	selectUser,
	setLocale
} from './slices/userSlice';
import {
	fetchSuppliersIfNeeded,
	selectCurrentSupplier,
	selectSuppliersArray
} from './slices/suppliersSlice';
import Auth from './modules/auth/Auth';
import { resetErrorTable, selectErrorTable } from './slices/errorSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import Sublogins from './modules/Sublogins/Sublogins';
import Sublogin from './modules/Sublogins/Sublogin';
import { PERMISSIONS } from './libraries/permissions';
import Alerts from './common/Alerts/Alerts';
import CharlesRoute from './common/CharlesRoute/CharlesRoute';

const App = () => {
	const dispatch = useDispatch();
	const isAuthenticating = useSelector(selectIsAuthenticating);
	const user = useSelector(selectUser);
	const errorTable = useSelector(selectErrorTable);
	const locale = useSelector(selectLocale);
	const supplier = useSelector(selectCurrentSupplier);
	const suppliers = useSelector(selectSuppliersArray);
	const authenticateError = useSelector(selectAuthenticateErrors);
	const [navCollapsed, setNavCollapsed] = useState(false);
	const hasTechnicalPermission = true;
	const hasCommercialPermission = true;

	useEffect(() => {
		document.cookie = `suppliers_client_version=${process.env.REACT_APP_APP_VERSION};max-age=9676800;`;
		dispatch(fetchUserIfNeeded());
		dispatch(fetchSuppliersIfNeeded());
	}, [dispatch]);

	useEffect(() => {
		if (authenticateError && !window.location.href.includes('auth')) {
			window.location.href = config.logoutUrl;
		}
	}, [authenticateError]);

	if (isAuthenticating) return <SplashScreen />;

	const navItems = user.routes.map(route => ({ ...route, title: t(`nav.${route.title}`) }));

	const origin = window.location.origin;
	const allowedRoutes = navItems.map(navItem => navItem.href).filter(href => href.includes(origin)).map(localNavLink => {
		return localNavLink.split(origin)[1];
	});



	const renderBottom = ({ collapsed }) => (
		<LanguageSelector
			size={collapsed ? 'small' : 'large'}
			language={locale}
			languages={{
				nl: { large: 'Nederlands', small: 'NL' },
				fr: { large: 'Français', small: 'FR' },
				en: { large: 'English', small: 'EN' }
			}}
			onChange={(l) => dispatch(setLocale({ locale: l }))}
		/>
	);



	return (
		<Router>
			<div className={`App ${navCollapsed ? 'nav--collapsed' : ''}`}>
				<Switch>
					<Route path='/auth' component={Auth} />
					<Route path='/'>
						<div className={'wrapper'}>
							<Navigation
								toggleCollapse={() => setNavCollapsed(!navCollapsed)}
								collapsed={navCollapsed}
								supplier={supplier}
								suppliers={suppliers}
								locale={locale}
								navItems={navItems}
								username={user.username}
								renderBottom={renderBottom}
								t={t}
								portalBaseUrl={config.portalUrl}
								renderLogout={() => (
									<Link className={'user__item user__logout'} to={config.logoutUrl}>
										<div className={'item__description'}>
											{t('nav.logout')}
										</div>
										<div className={'nav__icon'}>
											<FontAwesomeIcon icon={faSignOutAlt} />
										</div>
									</Link>
								)}
								versionNumber={`Suppliersportal v${process.env.REACT_APP_APP_VERSION}`}
								company={user.company ?? {}}
							/>
							<main>
								<Alerts />
								<Switch>
									<CharlesRoute exact path='/'
									    render={
									    	() => {
												if (
													(hasTechnicalPermission && allowedRoutes.includes('/products/technical')) ||
													(hasCommercialPermission && allowedRoutes.includes('/products/commercial'))
												) {
													return (<Redirect to='/products' />);
												}
											    if (allowedRoutes.includes('/sublogins')) return (<Redirect to='/sublogins' />);
											    return null;
											}
										}
									/>

									<CharlesRoute exact path='/terms' component={Terms} />
									<CharlesRoute exact path='/disclaimer' component={Terms} />
									<CharlesRoute exact path='/suppliers' component={Suppliers} />
									<CharlesRoute exact path='/sublogins' component={Sublogins} permission={PERMISSIONS.SUBACCOUNTS_VIEW} />
									<CharlesRoute path='/sublogins' component={Sublogin} permission={PERMISSIONS.SUBACCOUNTS_VIEW} />

									<CharlesRoute exact path='/products'
										render={
											() => {
												if (hasTechnicalPermission && allowedRoutes.includes('/products/technical')) return (<Redirect to='/products/technical' />);
												if (hasCommercialPermission && allowedRoutes.includes('/products/commercial')) return (<Redirect to='/products/commercial' />);
												return null; // TODO: make 'no permission' screen
											}
										}
									/>

									<CharlesRoute exact path='/products/technical' component={Technical} permission={PERMISSIONS.TECHNICAL_DATA} />
									<CharlesRoute exact path='/products/technical/:id' component={TechnicalDetail} permission={PERMISSIONS.TECHNICAL_DATA} />

									<CharlesRoute exact path='/products/commercial' component={Commercial} permission={PERMISSIONS.COMMERCIAL_DATA} />
									<CharlesRoute path='/products/commercial/:id' component={CommercialDetail} permission={PERMISSIONS.COMMERCIAL_DATA} />
								</Switch>

								<ToastContainer
									position={toast.POSITION.TOP_RIGHT}
									autoClose={false}
									closeOnClick={false}
									draggable={false}
									className={'charles-toast__container'}
									toastClassName={'charles-toast'}
									bodyClassName={'charles-toast__body'}
									progressClassName={'charles-toast__progress'}
								/>

								{process.env.REACT_APP_ENVIRONMENT && process.env.REACT_APP_ENVIRONMENT !== 'production' && (
									<>
										<div className={'app__environment'}>
											Test-environment ({process.env.REACT_APP_ENVIRONMENT})
										</div>
										{errorTable && (
											<div className={'errorTable'}>
												<Button onClick={() => dispatch(resetErrorTable())}>reset</Button>
												<div dangerouslySetInnerHTML={{ __html: errorTable }} />
											</div>
										)}
									</>
								)}
							</main>
						</div>
					</Route>
				</Switch>
			</div>
		</Router>
	);

};

export default App;
