const portalUrl = process.env.REACT_APP_PORTAL_URL;
const api = `${portalUrl }/api`;
const loginUrl = '/auth/login';
const logoutUrl = '/auth/logout';

export const IMAGE = {
	PACKSHOT: 'PS',
	MOODSHOT: 'SF'
};

export const config = {
	api: api,
	portalUrl,
	secondsBeforeProductsInvalidated: process.env.REACT_APP_SECONDS_UNTIL_PRODUCTS_INVALIDATED || 900,
	loginUrl,
	logoutUrl,
	IMAGE
};

export default config;
