export enum SubmissionStatus {
	NEW = 0,
	SUBMITTED = 10,
	REMOVED = 97,
	INVALID = 98,
	REJECTED = 99,
	APPROVED = 100
}

export enum SubmissionCategory {
	TEXT = 'text',
	IMAGE = 'images'
}

export enum SubmissionType {
	NL = 'nl',
	FR = 'fr',
	EN = 'en',
	DE = 'de',
	PACKSHOT = 'PS',
	MOODSHOT = 'SF'
}

export interface RejectReason {
	code: number | string,
	description: string
}

export interface Submission {
	status: SubmissionStatus,
	registered?: {
		user: string,
		date: Date
	},
	processed?: {
		user: string,
		date: Date
	},
	category: SubmissionCategory,
	type: SubmissionType,
	subtype: number,
	version: number,
	value: string,
	reasons?: Array<RejectReason>,
	action?: any,
	letCharlesDoIt?: boolean
}

export interface TypeAmounts {
	min: number,
	max: number
}

export interface CategoryType {
	type: SubmissionType,
	amounts: TypeAmounts
}

export interface MetadataCategory {
	category: SubmissionCategory,
	types: Array<CategoryType>
}

export interface MetadataInput {
	// common: {
	// 	customer_portal: {
	// 		article_image_location: string
	// 	}
	// },
	categories: {
		[category: string]: {
			location?: string,
			types: {
				[type: string]: {
					min: number,
					max: number
				}
			}
		}
	}
}

export interface ProductCommercialData {
	status: SubmissionStatus
}
