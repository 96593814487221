import { createSlice } from '@reduxjs/toolkit';

export const errorSlice = createSlice({
	name: 'error',
	initialState: {
		html: null
	},
	reducers: {
		setErrorTable: (state, { payload }) => {
			state.html = payload.html;
		},
		resetErrorTable: (state) => {
			state.html = null;
		}
	}
});
export const {
	setErrorTable,
	resetErrorTable
} = errorSlice.actions;

export const selectErrorTable = state => state.error.html;

export default errorSlice.reducer;
