import React from 'react';
import './Terms.scss';
import { t } from './../../libraries/i18n';

export default function Terms() {
	return (
		<div className="Terms">
			<h1>{t('common.terms_title')}</h1>
			<p dangerouslySetInnerHTML={{ __html: t('common.terms_text') }} />
		</div>
	);
}
