import { logger } from './logger/logger';
import * as api from './api';

const defaultLocale = 'nl';
const varRegex = /{(.+?)}/g;

const translations: Record<string, any> = {};
let selectedLocale: string;

export function init(locale: string) {
	return new Promise<void>((resolve, reject) => {
		fetchTranslations(locale)
			.then(data => {
				translations[locale] = data;
				logger.debug('TRANSLATIONS: Translations have been set', { locale, translations });
				resolve();
			})
			.catch(reject);
	});
}

function fetchTranslations(locale: string) {
	return new Promise((resolve, reject) => {
		api.get(`/suppliers.client/translations/${locale}`)
			.then(data => {
				logger.debug('TRANSLATIONS: Received translations', { translations: data, locale });
				resolve(data);
			})
			.catch(reject);
	});
}

export function t(stringId: string, ...vars: Array<any>) {
	const localeString = findTranslation(stringId, getLocale());
	const translationFailed = !localeString;
	const string = localeString ? localeString : stringId;
	if (translationFailed){
		if (vars.length) {
			return `${string } - ${ JSON.stringify(vars)}`;
		} else {
			return string;
		}
	}
	return replaceVarsIfAny(string, vars);
}

export function translate(translations: Record<string, any>, stringId: string) {
	return translations?.[getLocale()] ?? stringId;
}

function findTranslation(stringId: string, locale: string) {
	logger.silly('TRANSLATIONS: findTranslation START', { locale: locale, stringId: stringId });

	// Split the id
	const split = stringId.split('.');
	const module = split[0];
	const id = split[1];
	const moduleStrings = translations[locale]?.[module];

	// Module not available/found
	if (!moduleStrings) {
		logger.debug('TRANSLATIONS: Could not find a module', {
			locale: locale,
			stringId: stringId,
			module: module,
			id: id,
			moduleStrings: moduleStrings
		});
		return null;
	}

	// Get the ids of the strings
	const ids = Object.keys(moduleStrings).filter(s => {
		return s.startsWith(id);
	});

	// Get the strings of the ids
	const strings: Record<string, any> = {};
	ids.forEach(id => {
		const count = id.replace(`${split[1] }_`, '');
		strings[count] = moduleStrings[id];
	});

	// Only return the whole array if there are plurals
	let string;
	if (Object.keys(strings).includes('other')) {
		string = strings;
	} else {
		string = strings[id];
	}

	// Return the string
	logger.silly('TRANSLATIONS: findTranslation END', {
		strings: strings,
		string: string,
		stringId: stringId
	});
	return string;
}

function replaceVarsIfAny(string: string, vars: Array<any>) {
	logger.silly('TRANSLATIONS: replaceVarsIfAny', {
		string: string,
		vars: vars
	});

	if (!vars.length) return string;
	if (typeof string === 'object') {
		string = getPluralString(string, vars[0]);
	}

	const matches = string.match(varRegex);
	if (matches === null) return string;

	if (matches.length > vars.length) {
		throw new Error(`There are ${matches.length} placeholders in the translation string "${string}" and only ${vars.length} parameters are given. There should be exactly one parameter for each placeholder.`);
	}

	matches.forEach(match => {
		const index = match.substr(1, match.length - 1);
		const intIndex = parseInt(index) - 1;

		string = string.replace(match, vars[intIndex]);
	});

	return string;
}

function getPluralString(string: Record<string, any>, count: number) {
	let pluralString = string.other;

	if ((string['0'] || string['0'] === '') && count === 0) {
		pluralString = string['0'];
	} else if ((string['1'] || string['1'] === '') && count === 1) {
		pluralString = string['1'];
	}

	return pluralString;
}

export const setLocale = async (loc: string) => {
	selectedLocale = loc;
	api.post(`/language/${loc}`).catch(e => console.error(e));
	await loadSelectedTranslation();
};

const loadSelectedTranslation = async () => {
	if (!translations[selectedLocale]) {
		translations[selectedLocale] = await fetchTranslations(selectedLocale);
	}
};

export function getLocale() {
	return selectedLocale ?? defaultLocale;
}
