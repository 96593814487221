import { useMemo } from 'react';
import useSWR, { mutate } from 'swr';
import { post } from './../libraries/api';
import { Associate } from './../interfaces/settings/sublogin';
import { fetcher } from './index';
import { logger } from '../libraries/logger/logger';
import { config } from '../config';

function transformSublogin(data: any) {
	if (!data) return null;
	return {
		...data,
		loginEnabled: data?.loginEnabled === 1,
		associates: data.associates?.map((a: Associate) => a.id.toString())
	};
}

export function useSublogins(accountId: string) {
	const { data, error } = useSWR(accountId && `/manage.supplier.sub.accounts/${accountId}`, fetcher);

	return useMemo( () => {
		try {
			const sublogins = data?.map(transformSublogin);
			return {
				sublogins,
				isLoading: !data && !error,
				error
			};
		} catch (e: any){
			logger.error('INVALID DATA ERROR', {
				endpoint: `${config.api}/manage.supplier.sub.accounts/${accountId}`,
				res: data,
				e
			});

			return {
				sublogins: undefined,
				isLoading: false,
				error: e
			};
		}
	}, [data, error, accountId]);

}

export function useSublogin(accountId: string, id: string) {
	const { data, error } = useSWR(accountId && `/manage.supplier.sub.accounts/${accountId}/${id}`, fetcher);

	return {
		sublogin: transformSublogin(data),
		isLoading: !data && !error,
		error,
		mutate: (...args: any) => mutate(accountId && `/manage.supplier.sub.accounts/${accountId}/${id}`, ...args)
	};
}

export async function updateSublogin(accountId: string, id: string, newSublogin: any) {
	return post(`/manage.supplier.sub.accounts/${accountId}/${id}`, JSON.stringify(newSublogin));
}

export async function createSublogin(accountId: string, newSublogin: any) {
	const data = {
		...newSublogin,
		language: newSublogin.language ?? 'nl',
		username: newSublogin.email,
		loginEnabled: 1
	};
	return post(`/manage.supplier.sub.accounts/${accountId}/new`, JSON.stringify(data));
}

export async function disableSublogin(accountId: string, id: string, sublogin: any) {
	const data = {
		...sublogin,
		loginEnabled: sublogin.loginEnabled ? 0 : 1
	};
	return post(`/manage.supplier.sub.accounts/${accountId}/${id}`, JSON.stringify(data));
}

export async function deleteSublogin(accountId: string, id: string) {

	return post(`/manage.supplier.sub.accounts/${accountId}/${id}/delete`);
}
