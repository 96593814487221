import React from 'react';
import './Label.scss';

function classList(classes) {
	return Object
		.entries(classes)
		.filter(entry => entry[1])
		.map(entry => entry[0])
		.join(' ');
}


function Label({
	color, borderOnly, textOnly, noUnderline, className, children
}) {
	const classes = classList({
		'border-only': borderOnly,
		'text-only': textOnly,
		'no-underline': noUnderline
	});

	return (
		<div
			className={`Label ${color?.toString() ?? 'default'} ${className ?? ''} ${classes}`}
		>
			<span className={'content'}>
				{children}
			</span>
		</div>
	);
}

export default Label;
