const dateTimeFormat = new Intl.DateTimeFormat('nl', {
	year: 'numeric',
	month: 'long',
	day: '2-digit',
	hour: '2-digit',
	minute: '2-digit'
});


export const formatDate = date => {
	const [{ value: day }, , { value: month }, , { value: year }, , { value: hour }, , { value: minute }] = dateTimeFormat.formatToParts(date);
	return `${day} ${month} ${year} ${hour}:${minute}`;
};

export const getDateTimeFromReversedDateTimeString = (dateString) => {
	if (!dateString) return null;
	return Date.parse(dateString);
};
