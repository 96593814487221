import './Submission.scss';
import React from 'react';
import { SubmissionCategory, SubmissionStatus } from '../../../interfaces/commercial';
import ValueSection from './ValueSection/ValueSection';
import StatusSection from './StatusSection/StatusSection';
import Text from './Text';
import Photo from './Photo';

export default function Submission(props) {
  const {
    id,
    category,
    type,
    value,
    setValue,
    version,
    status,
    processed,
    registered,
    removeAction,
    addAction,
    canDelete,
    deleting,
    getPhotoLocation,
    reasons,
    saveFailError,
    hasAction,
    isLast,
    disableInput,
    letCharlesDoIt,
  } = props;

  if (!status && !category && !value && !letCharlesDoIt) {
    return null;
  }

  // console.log('letCharlesDoIt?', value, status, letCharlesDoIt);
  const emptyValue = status === SubmissionStatus.NEW && (!value || value === '');

  const remove = () => {
    if (canDelete) {
      addAction(null, 'delete');
    }
  };

  console.log('type', type);

  return (
    <div className={'Submission'} key={version}>
      {!(disableInput && setValue) && (
        <ValueSection
          {...{
            status,
            deleting,
            hasAction,
          }}
          uploading={emptyValue && category === SubmissionCategory.IMAGE}
        >
          {category === SubmissionCategory.IMAGE && (
            <Photo
              id={id}
              emptyValue={emptyValue}
              setValue={setValue}
              getPhotoLocation={getPhotoLocation}
              value={value}
              type={type}
              validation={{ minsize: type === 'PS' ? 0.5 : 2 }}
            />
          )}
          {category === SubmissionCategory.TEXT && <Text setValue={setValue} emptyValue={emptyValue} value={value} status={status} disableInput={disableInput} />}
        </ValueSection>
      )}

      <StatusSection
        {...{
          status,
          processed,
          registered,
          reasons,
          removeAction,
          deleting,
          saveFailError,
          isLast,
          category,
        }}
        letCharlesDoIt={letCharlesDoIt}
        remove={canDelete && remove}
      />
    </div>
  );
}
