import './StatusSection.scss';
import React from 'react';
import { t } from '../../../../libraries/i18n';
import { Button } from '@abm-international/react-components';

import StatusLabel from '../../../../common/StatusLabel/StatusLabel';

import { faTimes, faTrashAlt, faUndoAlt } from '@fortawesome/free-solid-svg-icons';
import { SubmissionStatus } from '../../../../interfaces/commercial';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function StatusSection(props) {
	const {
		status, processed, registered, reasons, removeAction, remove, deleting, saveFailError, isLast, category, letCharlesDoIt
	} = props;

	if (typeof status !== 'number') {
		return null;
	}

	const getSaveFailContent = saveFailError => {
		switch (saveFailError.code ?? 0) {
			case 1009:
				return <span className="info">{t('commercial.label_info_save_fail_invalid-version')}</span>;
			case 1010:
				return <span className="info">{t('commercial.label_info_save_fail_incomplete-value')}</span>;
			case 1011:
				return <span className="info">{t('commercial.label_info_save_fail_outdated-version')}</span>;
			case 6000:
				return <span className="info">{t('commercial.label_info_save_fail_no-image')}</span>;
			case 6001:
				return <span className="info">{t('commercial.label_info_save_fail_wrong-size')}</span>;
			case 6002:
				return <span className="info">{t('commercial.label_info_save_wrong-extension')}</span>;
			case 6003:
				return <span className="info">{t('commercial.label_info_save_fail_converting-failed')}</span>;
			default:
				return (
					<>
						<span className="info">{t('commercial.label_info_save_fail')}</span>
						<span className="info">{saveFailError.message}</span>
					</>
				);
		}
	};

	return (
		<div className={'StatusSection'}>
			{status !== SubmissionStatus.NEW && <StatusLabel value={status} since={processed?.date ?? registered?.date ?? null} />}

			{status === SubmissionStatus.REJECTED && !!reasons.length && (
				<ul className="reasons">
					{reasons.map((reason, index) => {
						if (`${reason.code}` === '0') {
							if (reason.description.startsWith('- ')) {
								const customReasons = reason.description.slice(2).split('\n- ');
								return customReasons.map((description, cIndex) => <li key={`${index}_${cIndex}`}>{description}</li>);
							}

							return <li key={index}>{reason.description}</li>;
						}

						return <li key={index}>{t(`commercial.reason_${reason.code}_${category}`)}</li>;
					})}
				</ul>
			)}

			{!!status && letCharlesDoIt && (
				<div className="StatusLabel">
					{status >= 99 && <span className="status status--0">{t(category === 'images' ? 'commercial.generated_by_charles' : 'commercial.translated_by_charles')}</span>}
					{status < 99 && (
						<span className="status status--0">{t(category === 'images' ? 'commercial.to-be-generated_by_charles' : 'commercial.to-be-translated_by_charles')}</span>
					)}
				</div>
			)}

			{status === SubmissionStatus.NEW && (
				<Button className="remove" onClick={removeAction} icon={<FontAwesomeIcon icon={faTimes} />} textOnly noUnderline>
					{t('commercial.cancel_action')}
				</Button>
			)}

			{remove && (
				<Button className="remove" onClick={remove} icon={<FontAwesomeIcon icon={faTrashAlt} />} textOnly noUnderline>
					{t('commercial.delete')}
				</Button>
			)}

			{deleting && isLast && (
				<div className="deleting-info">
					<span className="info">{t('commercial.label_info_delete')}</span>
					<Button className="undo" onClick={removeAction} icon={<FontAwesomeIcon icon={faUndoAlt} />} textOnly noUnderline>
						{t('commercial.undo')}
					</Button>
				</div>
			)}
			{saveFailError && <div className="save-fail-info">{getSaveFailContent(saveFailError)}</div>}
		</div>
	);
}
