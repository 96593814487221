import './ValueSection.scss';
import React from 'react';

export default function ValueSection(props){
	const {
		children,
		status,
		deleting,
		uploading,
		hasAction
	} = props;

	return (
		<div className={`ValueSection status--${status}${deleting ? ' deleting' : ''}${uploading ? ' uploading' : ''}${hasAction ? ' hasAction' : ''}`}>
			{children}
		</div>

	);

}
