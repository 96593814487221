import React, { useEffect, useState } from 'react';
import { t } from '../../../libraries/i18n';
import { Spinner } from '@abm-international/react-components';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { fetchUserIfNeeded } from '../../../slices/userSlice';
import { useDispatch } from 'react-redux';
import * as api from './../../../libraries/api';
import { fetchSuppliersIfNeeded } from '../../../slices/suppliersSlice';


export const Login = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [errors, setErrors] = useState([]);
	const [loading, setLoading] = useState(false);
	const [isAlreadyAuthenticated, setIsAlreadyAuthenticated] = useState(false);
	const [authenticatedUser, setAuthenticatedUser] = useState(null);

	// Check if user is already authenticated
	useEffect(() => {
		setLoading(true);

		const checkIfAlreadyAuthenticated = async () => {
			try {
				const res = await api.checkAuth();
				const newIsAlreadyAuthenticated = (res?.success && res.isAuthenticated) ?? false;

				setIsAlreadyAuthenticated(newIsAlreadyAuthenticated);
				setAuthenticatedUser(res.username);
			} catch (error) {
				console.error('Auth check failed', error);
			}

			setLoading(false);
		};

		checkIfAlreadyAuthenticated();
	}, []);

	const authenticate = async e => {
		e.preventDefault();
		setLoading(true);
		setErrors([]);
		try {
			const data = await api.authenticate(username, password);
			if (data) {
				dispatch(fetchUserIfNeeded());
				dispatch(fetchSuppliersIfNeeded());
				const urlParams = new URLSearchParams(location.search);
				const redirect = urlParams.get('redirect');

				if (data.isAlreadyAuthenticated) {
					setIsAlreadyAuthenticated(true);
					setAuthenticatedUser(data.username);
					return;
				}

				if (redirect) {
					history.push(redirect);
				} else if (data.redirectTo) {
					window.location.href = data.redirectTo;
				} else {
					history.push('/');
				}
			}
		} catch (e) {
			if (e?.maintenanceMode){
				setErrors([{ message: 'errors.error_code_maintenance_mode' }]);
			} else {
				setErrors([{ message: `errors.error_code_${e.code || e?.result?.status}` }]);
			}
		}
		setLoading(false);
	};

	const renderErrors = () => (
		<ul className={'form__errors'}>
			{errors.map((error, key) => (
				<li key={key}>
					{t(error.message)}
				</li>
			))}
		</ul>
	);

	return isAlreadyAuthenticated ? (
		<p>
			{authenticatedUser ? t('auth.already_logged_in_as', authenticatedUser) : t('auth.already_logged_in')}
			<br />
			<Link to="/auth/logout">{t('auth.click_here')}</Link> {t('auth.to_log_out')}
		</p>
	) : (
		<>
			<form className={'form'} onSubmit={authenticate}>
				<label>
					{t('auth.username_placeholder')}

					<input
						type='text'
						value={username}
						onChange={e => setUsername(e.target.value)}
					/>
				</label>

				<label>
					{t('auth.password_placeholder')}

					<input
						type='password'
						value={password}
						onChange={e => setPassword(e.target.value)}
					/>
				</label>

				{errors && renderErrors()}

				<button className={'button button--alt'} disabled={!username || !password || loading}>
					{!loading && t('auth.login_button')}
					{loading && (<Spinner size='small' />)}
				</button>
			</form>
			<Link className={'link_to'} to={'/auth/forgot'}>{t('auth.forgot_password')}</Link>
		</>
	);
};

export default Login;
