import './SaveButton.scss';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { t } from './../../../libraries/i18n';
import * as api from './../../../libraries/api';

import { Button, Spinner } from '@abm-international/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCloudUploadAlt, faPen, faSave } from '@fortawesome/free-solid-svg-icons';
import { selectSupplierId } from './../../../slices/suppliersSlice';

export default function SaveButton(props) {
	const supplier = useSelector(selectSupplierId);
	const [saveFailed, setSaveFailed] = useState(false);

	const {
		specifications, locales, vvoDocs, localesVvoDocs, allergens, useSaving, id, mutate, canApprove, canSave, isEditable, setEditable, allergenDefinitions
	} = props;

	const [saving, setSaving] = useSaving;

	const handleSave = async approve => {
		const detail = {
			allergens: {},
			specifications: Object.fromEntries(
				specifications.map(({ language, name }) => {
					const toUpdateExpiredDate = locales.find(l => l.lang === language);
					return [language, { filename: name, renewed: toUpdateExpiredDate.selected }];
				})
			),
			vvoDocs: Object.fromEntries(
				vvoDocs.map(({ language, name }) => {
					const toUpdateExpiredDate = localesVvoDocs.find(l => l.lang === language);
					return [language, { filename: name, renewed: toUpdateExpiredDate.selected }];
				})
			),
			isApproved: approve,
		};

		if (Object.values(allergens).length) {
			// user specifically stated the allergens
			detail.allergens = Object.fromEntries(allergenDefinitions.map(ad => [ad, allergens[ad] ?? false]));
		} else {
			// user did NOT specifically state any allergens
			detail.allergens = {};
		}

		setSaving(true);
		setSaveFailed(false);

		try {
			const res = await api.saveTechnicalProduct(supplier, id, detail);
			await mutate({}, true);
			if (!res.success) throw res;
			toast.success(t('products.save_success'), { autoClose: 3000 });
			if (approve) setEditable(false);
		} catch (error) {
			toast.error(t('products.save_failed'));
			setSaveFailed(true);
		}

		setSaving(false);
	};

	return (
		<div className={'TechnicalSaveButton'}>
			{!isEditable && (
				<Button onClick={() => setEditable(true)} className={'edit'}>
					<>
						<FontAwesomeIcon icon={faPen} />
						{t('products.edit_approved_product')}
					</>
				</Button>
			)}
			{isEditable && (
				<>
					{canSave && (
						<Button disabled={saving} onClick={() => handleSave(false)} className={saveFailed ? 'fail' : ''} textOnly>
							{saving && <Spinner size={'small'} />}
							{!saving && (
								<>
									<FontAwesomeIcon icon={faSave} />
									{t('products.save')}
								</>
							)}
						</Button>
					)}

					<Button disabled={saving || !canApprove} onClick={() => handleSave(true)} className={saveFailed ? 'fail' : ''}>
						{saving && <Spinner size={'small'} />}
						{!saving && (
							<>
								<FontAwesomeIcon icon={faCloudUploadAlt} />
								{t('products.save_and_approve')}
							</>
						)}
					</Button>
				</>
			)}
		</div>
	);
}
