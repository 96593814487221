import React, { useState } from 'react';
import './SafeImg.scss';
import { Spinner } from '@abm-international/react-components';

export default function SafeImg({ src, alt, className }){
	const [imageNotLoadable, setImageNotLoadable] = useState(false);
	const [loading, setLoading] = useState(true);

	if (imageNotLoadable) {
		return (
			<span className={'SafeImg ImagePlaceholder'}>
				no image
			</span>
		);
	}

	return (
		<span className={'SafeImg'}>
			<img
				hidden={loading}
				className={className}
				src={src ?? '/placeholder.png'}
				alt={alt ?? ''}
				onLoad={() => setLoading(false)}
				onError={() => setImageNotLoadable(true)}
			/>
			{loading && <Spinner size={'small'} />}
		</span>
	);
}
