import './CommercialDetail.scss';
import React, { useEffect, useState } from 'react';
import {
	useSelector
} from 'react-redux';
import { selectSupplierId } from './../../../slices/suppliersSlice';
import SaveButton from './../SaveButton/SaveButton';
import { useMetadata, useProduct } from './../../../api/commercial';
import {
	ProductNavigator,
	ProductHeader
} from './../../../common';
import SubmissionSection from './../SubmissionSection/SubmissionSection';
import {
	Route, Switch, useHistory, useParams
} from 'react-router';
import {
	CategoryType,
	MetadataCategory,
	Submission,
	SubmissionCategory,
	SubmissionType
} from '../../../interfaces/commercial';
import { Spinner } from '@abm-international/react-components';
import { getLocale, t } from '../../../libraries/i18n';
import useSupplierRoute from '../../../libraries/useSupplierRoute';
import Archive from '../Archive/Archive';
import { selectHasPermission } from '../../../slices/userSlice';

export default function CommercialDetail() {
	useSupplierRoute();
	const { id } = useParams<{ id: string }>();
	const history = useHistory();

	const supplierId = useSelector(selectSupplierId);

	const [actions, setActions] = useState<Array<Submission>>([]);
	const [saving, setSaving] = useState<boolean>(false);

	const {
		product,
		submissions,
		isLoading: isProductLoading,
		mutate,
		error
	} = useProduct(supplierId, id);
	const {
		categories,
		photoLocation,
		loading: isMetadataLoading
	} = useMetadata();

	useEffect(() => {
		if (error && error?.code === 1005) history.push('/products/commercial');
	}, [error, history]);

	const getProductName = (product: { id: string, reference: string, code: string, nameTranslations: { [lang: string]: string } } | undefined) => {
		if (!product) return 'Loading...';
		const locale = getLocale();
		let productName = product.reference ? `[${product.reference}] ` : '';
		productName += (product.nameTranslations[locale] || '');
		return productName;
	};
	const hasPermission = useSelector(state => selectHasPermission(state, 6022));
	document.title = `${getProductName(product)} | Charles Supplier Portal`;


	/**
	 * Get the full path of a photo (up)loaded through the commercialdetail tab.
	 * The metadata includes a photo location parameter that has keys that have to be replaced in it.
	 * the notation goes `path/suppliers/{supplier}/images/{article}/{type}` but can deviate.
	 * @param type `PS` or `SF`
	 * @param url should start with slash
	 */
	const getPhotoLocation = (type: SubmissionType, url: string) => {
		const vars = {
			supplier: supplierId,
			article: id,
			type
		};
		return Object.entries(vars).reduce(
			(location, [key, value]) => location.replace(`{${key}}`, value),
			`${photoLocation}${url}`
		);
	};



	const addAction = (submission: Submission, calback?: () => void) => {
		setActions([...actions, submission]);
	};
	const removeAction = (submission: Submission) => setActions(actions.filter((a) => a !== submission));
	const setAction = (action: Submission, value: string, letCharlesDoIt?: boolean) => setActions(actions.map((a) => a !== action ? a : { ...a, value, letCharlesDoIt }));


	const renderTypes = (category: SubmissionCategory, types: Array<CategoryType>) =>
		types.map(({ type, amounts }: CategoryType) => (
			<SubmissionSection
				id={id}
				key={type}
				submissions={submissions.filter((s: Submission) => s.category === category && s.type === type)}
				actions={actions.filter((a: Submission) => a.category === category && a.type === type)}
				category={category}
				type={type}
				amounts={amounts}
				addAction={addAction}
				removeAction={removeAction}
				setAction={setAction}
				getPhotoLocation={getPhotoLocation}
			/>
		));

	const renderCategories = (categories: Array<MetadataCategory>) =>

		categories.map((category: MetadataCategory) => (
			<div className='category' key={category.category}>
				<h2>{t(`commercial.title_${category.category}`)}</h2>
				{category.category === 'text' && <p dangerouslySetInnerHTML={{ __html: t(`commercial.text_${category.category}`) }} />}
				{category.category === 'images' && <p dangerouslySetInnerHTML={{ __html: t(`commercial.text_${category.category}`) }} />}
				{renderTypes(category.category, category.types)}
			</div>
		));

	const loading = isProductLoading || isMetadataLoading;
	if (loading || !product) return <Spinner />;

	const renderDetail = () => (
		<div className={'CommercialDetail'}>

			<ProductNavigator
				left={{
					url: '/products/commercial',
					text: 'commercial.button_to_overview'
				}}
			/>
			<ProductHeader product={product} />

			{categories && renderCategories(categories)}

			<SaveButton
				actions={actions}
				id={id}
				setActions={setActions}
				mutate={mutate}
				useSaving={[saving, setSaving]}
			/>
		</div>
	);

	return (
		<Switch>
			<Route exact path='/products/commercial/:id/archive/:category/:type' render={({ match: { params: { category, type } } }) => {
				const archiveSubmissions = submissions.filter((submission: Submission) => (submission.category === category && submission.type === type));
				if (hasPermission) return <Archive submissions={archiveSubmissions} product={product} category={category} type={type} getPhotoLocation={(url: string) => getPhotoLocation(type as SubmissionType, url)} />;
				return <></>;
			}} />
			<Route path='/' render={renderDetail} />
		</Switch>
	);
}
