/**
 * Returns the items that match the query
 * @param {string} query The Search query
 * @param {Array} items The items the filter should be applied on
 * @param {Array} keys The keys on which to apply the filter
 * @param {Boolean} scoreItems Whether the items should be given a score
 * @param {('OR','AND')} searchOperator Whether to match all the query parts or if one is enough
 * @param {Array} ignoredChars The Characters that are ignored when matching
 * @returns {string[]} Array of filtered items
 */
export function filterItemsByQuery(query = '', items: Array<Record<string, any>>, keys: Array<string>, scoreItems = false, searchOperator: 'AND' | 'OR' = 'AND', ignoredChars = ['\'']) {
	if (query === '') return items;

	// Trim whitespaces around and in query
	query = query.toLowerCase().trim();
	query = query.replace(/ +/g, ' ');

	const filteredItems = items.map(item => {
		return filterItemByQuery({ ...item }, keys, query, scoreItems, searchOperator, ignoredChars);
	});

	return filteredItems.filter(item => item !== null);
}

function filterItemByQuery(item: Record<string, any>, keys: Array<string>, query: string, scoreItems: boolean, searchOperator: 'AND' | 'OR', ignoredChars: Array<string>) {
	const queries = query.split(' ');

	let score = 0;

	// Check how many queries have a match
	// All queries should match to return true
	const queriesMap = queries.map(q => {

		// If at least one key has a match,
		// consider it a match for the query
		const keyMatch = keys.map(key => {
			const keyParts = key.split('.');
			let value = item;
			keyParts.forEach(k => {
				value = value[k];
			});

			if (!value) return false;
			const stringValue = value.toString().toLowerCase();
			const match = stringContainsQuery(stringValue, q, ignoredChars);

			score = match ? score + 1 : score;
			return match;
		});

		return keyMatch.indexOf(true) !== -1;
	});

	let containsAllQueries = false;
	if (searchOperator === 'OR') {
		containsAllQueries = queriesMap.indexOf(true) !== -1;
	} else if (searchOperator === 'AND') {
		containsAllQueries = queriesMap.indexOf(false) === -1;
	}

	if (containsAllQueries) {
		if (scoreItems) item.score = score;
		return item;
	}

	return null;
}

export function stringContainsQuery(string: string, query: string, ignoredChars: Array<string>) {
	query = query.replace(/[\\.+*?^$[\](){}/'#:!=|]/gi, '\\$&');

	ignoredChars.forEach(char => {
		query = query.replace(char, `${char }?`);
	});

	const regex = new RegExp(`(${query})`, 'gi');
	const contains = string.match(regex);
	return !!contains;
}

export function sortItems (a: Record<string, any>, b: Record<string, any>, direction: string, sortKey: string) {
	let sortValueA = a;
	let sortValueB = b;
	if (direction === 'descending') {
		sortValueA = b;
		sortValueB = a;
	}

	sortKey.split('.').forEach(keyPart => {
		sortValueA = sortValueA[keyPart];
		sortValueB = sortValueB[keyPart];
	});

	if (sortValueA > sortValueB) {
		return 1;
	} else if (sortValueA < sortValueB) {
		return -1;
	} else {
		return 0;
	}
}

export function rankItems(a: Record<string, any>, b: Record<string, any>, direction: string, sortKey: string) {
	if (a.score === b.score) return sortItems(a, b, direction, sortKey);
	return b.score - a.score;
}
