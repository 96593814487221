import { config } from './../config';
import { parseApiResponse } from './../libraries/api';
import ResponseError from './../libraries/errors/customError';


// TODO: check if optional options are not an issue live
export const fetcher = async (endpoint: string, options?: any) => {
	options = {
		...options,
		credentials: 'include'
	};

	try {
		const response = await fetch(config.api + endpoint, options);
		const res = await parseApiResponse(response);

		if (res.result.status >= 400) {
			throw ResponseError.getErrorFromResponse(res) ?? res;
		}
		return res.body;

	} catch (e: any) {
		if (e.code === 1003 || e.result?.status === 401) {
			window.location.href = config.loginUrl;
		}
		throw e;
	}
};

export const transformProduct = (data: any) => {
	if (!data) return undefined;

	return {
		id: data.article.id,
		reference: data.orderReference,
		code: data.article.S.code,
		nameTranslations: data.article.S.description,
	};
};
