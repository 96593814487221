import React, { useState } from 'react';
import FileUpload from './../../../common/FileUpload/FileUpload';
import * as api from './../../../libraries/api';
import { useSelector } from 'react-redux';
import { selectSupplierId } from './../../../slices/suppliersSlice';
import { toast } from 'react-toastify';
import { t } from './../../../libraries/i18n';

/*
 *	Minimum dimension (width or height)
 *	Target size = 10 cm = 3.93701 inch (1 cm = 0.393701 inch)
 *	Target resolution = 300dpi
 *	Target dimension = size (in inch) * resolution (in dpi) = 3.93701 * 300 = 1.181,103 ≠ 1200
 */
const MINIMUM_DIMENSION = 1200;
const MAXIMUM_DIMENSION = 12000; // 12000 = 100cm aan 300dpi

const getImageDimensions = async image => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      window.URL.revokeObjectURL(img.src);

      return resolve({ width, height });
    };
    img.onerror = () => {
      return reject();
    };

    img.src = window.URL.createObjectURL(image);
  });
};

export default function Photo(props) {
  const { id, value, emptyValue, getPhotoLocation, setValue, type, validation } = props;
  const [inputVersion, setInputVersion] = useState(Date.now());
  const [uploading, setUploading] = useState(false);
  const [uploadError, setUploadError] = useState(undefined);
  const supplierId = useSelector(selectSupplierId);

  const validateFile = async (file, validations) => {
    if (!file) {
      toast.error('No file selected');
      return false;
    }

    const validated = validations.reduce(async (acc, validation) => {
      if (!(await acc)) {
        return acc;
      }

      const val = validation.split(':');
      switch (val[0]) {
        case 'fileType':
          if (file.type === val[1]) {
            return true;
          }
          toast.error(t('commercial.upload_validation-file_type'));
          return false;

        case 'minSize':
          if (file.size / (1000 * 1000) >= Number(val[1])) {
            return true;
          }
          toast.error(t('commercial.upload_validation-min_size', val[1]));
          return false;

        case 'maxSize':
          if (file.size <= Number(val[1])) {
            return true;
          }
          toast.error(t('commercial.upload_validation-max_size', val[1]));
          return false;

        case 'minWidth':
        case 'minHeight':
        case 'maxWidth':
        case 'maxHeight':
          const { width, height } = await getImageDimensions(file);

          if (val[0] === 'minWidth') {
            if (width >= val[1]) {
              return true;
            }
            toast.error(t('commercial.upload_validation-min_width', val[1]));
            return false;
          }

          if (val[0] === 'minHeight') {
            if (height >= val[1]) {
              return true;
            }
            toast.error(t('commercial.upload_validation-min_height', val[1]));
            return false;
          }

          if (val[0] === 'maxWidth') {
            if (width <= val[1]) {
              return true;
            }
            toast.error(t('commercial.upload_validation-max_width', val[1]));
            return false;
          }

          if (val[0] === 'maxHeight') {
            if (height <= val[1]) {
              return true;
            }
            toast.error(t('commercial.upload_validation-max_height', val[1]));
            return false;
          }

          return false;
        default:
          return acc;
      }
    }, true);

    return validated;
  };

  const upload = async ([file]) => {
    if (
      !(await validateFile(file, [
        `minSize:${validation?.minsize || 0.5}`,
        'fileType:image/jpeg',
        `minWidth:${MINIMUM_DIMENSION}`,
        `minHeight:${MINIMUM_DIMENSION}`,
        `maxWidth:${MAXIMUM_DIMENSION}`,
        `maxHeight:${MAXIMUM_DIMENSION}`,
      ]))
    ) {
      return;
    }

    setUploading(true);

    try {
      const imageData = new FormData();
      imageData.append('image', file);
      const res = await api.uploadImage(supplierId, id, type, imageData);
      console.log(('res', res));
      if (!res.url) throw res.error;
      setValue(res.url); // TODO: this overwrites what you did during upload
      setUploading(false);
    } catch (e) {
      if (e.error_code === 6000) {
        toast.error(t('commercial.upload_failed-not_a_picture'));
      } else if (e.error_code === 6001) {
        toast.error(t('commercial.upload_failed-size_out_of_range'));
      } else if (e.error_code === 6002) {
        toast.error(t('commercial.upload_failed-wrong_format'));
      } else if (e.error_code === 6003) {
        toast.error(t('commercial.upload_failed-conversion_failed'));
      } else {
        toast.error(t('commercial.upload_failed'));
      }
      setUploadError(e);
      setInputVersion(Date.now());
      setUploading(false);
    }
  };

  if (!emptyValue) {
    return <img src={getPhotoLocation(value)} alt={''} />;
  }
  return <FileUpload onDrop={upload} uploading={uploading} uploadError={uploadError} accept={'image/jpeg'} key={inputVersion} />;
}
