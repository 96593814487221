import { createSlice } from '@reduxjs/toolkit';
import * as api from './../libraries/api';
import { logoutSuccess, selectAccountId } from './userSlice';

export const suppliersSlice = createSlice({
	name: 'suppliers',
	initialState: {
		currentSupplier: null,
		items: null,
		fetchFailed: false
	},
	reducers: {
		setCurrentSupplier: (state, { payload }) => {
			const { code } = payload;
			state.currentSupplier = code;
		},
		setSuppliers: (state, { payload }) => {
			const { suppliers } = payload;
			state.items = suppliers.reduce((suppliersObj, supplier) => {
				suppliersObj[supplier.code] = supplier;
				return suppliersObj;
			}, {});
		},
		fetchSuppliersStart: state => {
			state.fetching = true;
			state.fetchFailed = false;
		},
		fetchSuppliersSuccess: state => {
			state.fetching = false;
		},
		fetchSuppliersFailed: state => {
			state.fetching = false;
			state.fetchFailed = true;
		},
	},
	extraReducers: {
		[logoutSuccess]: state => {
			state.currentSupplier = null;
			state.items = {};
			state.fetchFailed = false;
		}
	}
});
export const {
	setCurrentSupplier,
	setSuppliers,
	fetchSuppliersStart,
	fetchSuppliersSuccess,
	fetchSuppliersFailed
} = suppliersSlice.actions;

export const selectCurrentSupplier = state => {
	const supplierId = selectSupplierId(state);
	if (!supplierId) return undefined;
	return state.suppliers.items?.[supplierId] ?? { id: supplierId };
};
export const selectSupplierId = state => {
	const savedSupplier = localStorage.getItem('supplier');
	const accountId = selectAccountId(state);
	const localStorageIsValid = accountId ? +accountId === +localStorage.getItem('me') : true;
	if (savedSupplier && localStorageIsValid) return savedSupplier;
	return state.suppliers.currentSupplier;
};

export const selectSuppliers = state => state.suppliers.items;
export const selectSuppliersArray = state => Object.values(selectSuppliers(state) ?? {});
export const selectShouldFetch = state => !selectSuppliersArray(state).length && !state.suppliers.fetching;// && !state.suppliers.fetchFailed;

export const fetchSuppliersIfNeeded = () => async (dispatch, getState) => {
	const state = getState();
	if (selectShouldFetch(state)) {
		dispatch(fetchSuppliersStart());
		try {
			const suppliers = await api.getSuppliers();
			dispatch(fetchSuppliersSuccess());
			dispatch(setSuppliers({ suppliers }));
		} catch (e) {
			dispatch(fetchSuppliersFailed());
		}
	}
};

export const resetSupplier = () => dispatch => {
	localStorage.removeItem('supplier');
	localStorage.removeItem('me');
	dispatch(setCurrentSupplier({ code: null }));
};

export const setSupplier = ({ code }, next) => (dispatch, getState) => {
	const id = selectAccountId(getState());
	dispatch(setCurrentSupplier({ code }));
	localStorage.setItem('supplier', code);
	localStorage.setItem('me', id);
	sessionStorage.removeItem('technicalFilters');
	sessionStorage.removeItem('commercialFilters');
	next();
};

export default suppliersSlice.reducer;
