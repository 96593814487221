import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
	forgotPasswordSubmit,
	forgotPasswordSuccess,
	forgotPasswordTitle,
	usernamePlaceholder
} from '../authTranslations';
import {
	forgot, selectForgotErrors, selectForgotLoading, selectForgotSucceeded, selectLocale
} from '../../../slices/userSlice';
import { t } from '../../../libraries/i18n';

import { Spinner } from '@abm-international/react-components';
import { Link } from 'react-router-dom';


export const Forgot = () => {
	const dispatch = useDispatch();
	const [username, setUsername] = useState('');
	const errors = useSelector(selectForgotErrors);
	const isLoading = useSelector(selectForgotLoading);
	const succeeded = useSelector(selectForgotSucceeded);
	useSelector(selectLocale);

	const submit = e => {
		e.preventDefault();
		dispatch(forgot({ username }));
	};

	const renderErrors = () => (
		<ul className={'form__errors'}>
			{errors.map((error, key) => (
				<li key={key}>
					{t(error.message)}
				</li>
			))}
		</ul>
	);

	return (
		<>
			<h1>{forgotPasswordTitle()}</h1>

			<form className={'form'} onSubmit={submit}>
				<label>
					{usernamePlaceholder()}
					<input
						type='text'
						value={username}
						onChange={e => setUsername(e.target.value)}
						disabled={succeeded}
					/>
				</label>

				{errors && renderErrors()}

				{!succeeded && (
					<button className={'button button--alt'} disabled={!username || isLoading}>
						{!isLoading && forgotPasswordSubmit()}
						{isLoading && (<Spinner size='small' />)}
					</button>
				)}

				{succeeded &&
					<div className={'email-sent-hint'}>
						{forgotPasswordSuccess()}
					</div>
				}
			</form>
			<Link className={'link_to'} to={'/auth/login'}>{t('auth.login_title')}</Link>
		</>
	);
};

export default Forgot;
