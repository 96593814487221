import React from 'react';
import './Archive.scss';
import { t } from '../../../libraries/i18n';
import { ProductNavigator, ProductHeader, StatusLabel } from './../../../common';
import { SubmissionStatus } from './../../../interfaces/commercial';

export default function Archive({ submissions, product, category, type, getPhotoLocation }) {
  const renderSubmission = ({ value, status, processed, registered, version, reasons, removed }) => {
    return (
      <div className={'submission'} key={version}>
        {category === 'text' && <div className={'text'}>{value}</div>}
        {category === 'images' && <img src={getPhotoLocation(value)} className={'image'} alt={''} />}

        <div className={'statusBlock'}>
          {removed && status !== 98 && <StatusLabel value={removed.status} since={removed.date} user={removed.user} module={'archive'} />}
          {processed && <StatusLabel value={status} since={processed.date} user={processed.user} module={'archive'} />}
          {status === SubmissionStatus.REJECTED && (
            <ul className="reasons">
              {reasons.map((reason, index) => {
                if (`${reason.code}` === '0') {
                  if (reason.description.startsWith('- ')) {
                    const customReasons = reason.description.slice(2).split('\n- ');
                    return customReasons.map((description, cIndex) => <li key={`${index}_${cIndex}`}>{description}</li>);
                  } else {
                    return <li key={index}>{reason.description}</li>;
                  }
                } else {
                  return <li key={index}>{t(`commercial.reason_${reason.code}_${category}`)}</li>;
                }
              })}
            </ul>
          )}
          <StatusLabel value={SubmissionStatus.SUBMITTED} since={registered?.date ?? null} user={registered.user} module={'archive'} />
        </div>
      </div>
    );
  };

  const renderSubtype = submissions => {
    submissions.sort((a, b) => b.version - a.version);
    if (type === 'SF') {
      if (submissions.length === 1) {
        return (
          <div className={'subtype'} key={submissions[0].subtype}>
            {renderSubmission(submissions[0])}
          </div>
        );
      } else {
        return (
          <div className={'subtype'} key={submissions[0].subtype}>
            {renderSubmission({
              ...submissions[1],
              removed: {
                date: submissions[0].processed?.date,
                user: submissions[0].processed.user,
                status: submissions[0].status,
              },
            })}
          </div>
        );
      }
    }
    return (
      <div className={'subtype'} key={submissions[0].subtype}>
        {submissions.map(renderSubmission)}
      </div>
    );
  };

  const sortBySubtypeReducer = (subtypes, submission) => {
    return {
      ...subtypes,
      [submission.subtype]: [...(subtypes[submission.subtype] ?? []), submission],
    };
  };

  const subtypes = Object.values(submissions.reduce(sortBySubtypeReducer, {}));
  return (
    <div className={'Archive'}>
      {product && (
        <>
          <ProductNavigator
            left={{
              url: `/products/commercial/${product.id}`,
              text: 'archive.button_to_item',
            }}
          />
          <ProductHeader product={product} />
          <h2 className={'subtitle'}>
            <span>{t('archive.title')}</span>
            <span>{t(`archive.category_${category}`)}</span>
            <span>{'>'}</span>
            <span>{t(`commercial.title_${category}_${type}`)}</span>
          </h2>
          {subtypes.map(renderSubtype)}
        </>
      )}
    </div>
  );
}
