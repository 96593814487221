export default class ResponseError extends Error {
	constructor(code, message, result, exception = null) {
		super(message);
		this.name = 'CustomError';
		this.code = code;
		this.exception = exception;
		this.result = result;
		this.body = {
			// eslint-disable-next-line camelcase
			error_code: this.code,
			// eslint-disable-next-line camelcase
			error_message: this.message
		};
	}

	static getErrorFromResponse(res) {
		if (res?.body?.error_code) {
			return new ResponseError(res.body.error_code, res.body.error_message, res.result);
		} else {
			return null;
		}
	}
}
