import React from 'react';
import './Search.scss';

export const Search = ({ handleSearch, placeholder, query }) => {
	return (
		<input
			className="Search"
			type="text"
			onChange={handleSearch}
			placeholder={placeholder}
			value={query}
		/>
	);

};

export default Search;
