import { createSlice } from '@reduxjs/toolkit';
import * as api from './../../libraries/api';
import { selectUser } from './../../slices/userSlice';

export const loggerSlice = createSlice({
	name: 'log',
	initialState: {},
	reducers: {
		logStart: state => {
			state.isFetching = true;
		},
		logSuccess: state => {
			state.isFetching = false;
			state.valid = true;
		},
		logFailed: state => {
			state.isFetching = false;
		},
	}
});

export const {
	logStart,
	logFailed,
	logSuccess,
} = loggerSlice.actions;

export const log = (data, endpoint) => async (dispatch, getState) => {
	const state = getState();
	const user = selectUser(state);
	if (user) {
		data = {
			...data,
			'abm.session': user.session ?? '',
			'abm.user-account': user.account ?? '',
			'abm.user-name': user.username ?? ''
		};
	}
	dispatch(logStart());
	try {
		const result = await api.httpLog(data, endpoint);
		dispatch(logSuccess());
		console.log(result);
	} catch (error) {
		dispatch(logFailed());
		console.log(`Logging to endpoint failed: ${ error.message}`);
	}
};

export default loggerSlice.reducer;
