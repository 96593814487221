import './SaveButton.scss';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { t } from './../../../libraries/i18n';
import * as api from './../../../libraries/api';

import { Button, Spinner } from '@abm-international/react-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { selectSupplierId } from './../../../slices/suppliersSlice';

export default function SaveButton(props) {
	const supplier = useSelector(selectSupplierId);
	const [saveFailed, setSaveFailed] = useState(false);

	const {
		useSaving, actions, id, setActions, mutate
	} = props;
	const [saving, setSaving] = useSaving;

	const reasons = [];
	if (actions.length === 0) reasons.push('no-actions');
	if (actions.some(({ value, action, letCharlesDoIt }) => value === '' && !letCharlesDoIt && action !== 'delete')) reasons.push('no-value');
	// TODO: check if version is same as highest for updates and deletes
	// TODO: check if no version exists for creates

	const handleSave = async () => {
		setSaving(true);
		setSaveFailed(false);
		try {
			const res = await api.saveCommercialProduct(supplier, id, actions);
			if (!res.success) {
				setActions(
					actions
						.map((action, index) => ({
							...action,
							saveFailError: res.actions[index].error,
							// version: 'increase version'
						}))
						.filter((action, index) => !res.actions[index].success)
				);

				toast.error(t('products.save_failed'));
				setSaveFailed(true);
			} else {
				await mutate();
				setActions([]);
				toast.success(t('products.save_success'), { autoClose: 3000 });
			}
		} catch (error) {
			toast.error(t('products.save_failed'));
			setSaveFailed(true);
		}
		setSaving(false);
	};

	return (
		<div className={'SaveButton'}>
			<Button disabled={reasons.length > 0 || saving} onClick={handleSave} className={saveFailed ? 'fail' : ''}>
				{saving && <Spinner size={'small'} />}
				{!saving && (
					<>
						<FontAwesomeIcon icon={faCloudUploadAlt} />
						{t('common.save')}
					</>
				)}
			</Button>
		</div>
	);
}
