import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { selectSupplierId, selectSuppliers } from './../slices/suppliersSlice';

export default function useSupplierRoute() {
	const location = useLocation();
	const history = useHistory();
	const suppliers = useSelector(selectSuppliers);
	const supplierId = useSelector(selectSupplierId);

	// Go to supplier select if no id is set
	useEffect(() => {
		if (!supplierId) history.push(`/suppliers?from=${location.pathname}`);
	}, [supplierId, history, location]);

	// Go to supplier select if no access to id
	useEffect(() => {
		if (!supplierId) return;
		if (!suppliers) return;
		if (!Object.keys(suppliers).includes(supplierId)) history.push(`/suppliers?from=${location.pathname}`);
	}, [suppliers, supplierId, history, location]);
}
