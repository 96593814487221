import React from 'react';
import { SubmissionStatus } from './../../../interfaces/commercial';
import { FlextArea } from '@abm-international/react-components';

export default function Text(props) {
	const {
		value, status, setValue, emptyValue, disableInput
	} = props;
	return <FlextArea value={value} maxLength={330} onChange={setValue} readOnly={status !== SubmissionStatus.NEW || disableInput} autoFocus={emptyValue} />;
}
