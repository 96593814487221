import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

export default function useSearchParams() {
	const [params, setParams] = useState({});
	const location = useLocation();

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search);

		let search = {};
		for (const [key, value] of searchParams.entries()) {
			search = { ...search, [key]: value };
		}

		setParams(search);
	}, [location]);

	return params;
}
