import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import { logger } from './libraries/logger/logger';
import * as i18n from './libraries/i18n';
import BrowserConsoleTransport from './libraries/logger/browserConsoleTransport';
import HttpTransport from './libraries/logger/httpTransport';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faArrowLeft, faCaretDown, faCaretUp, faTimes
} from '@fortawesome/free-solid-svg-icons';
import { Provider } from 'react-redux';
import store from './store';

library.add(faCaretUp, faCaretDown, faArrowLeft, faTimes);

logger.init({
	transports: [
		new BrowserConsoleTransport({
			level: process.env.REACT_APP_BROWSER_LOGGING_LEVEL || 'info'
		}),
		new HttpTransport({
			endpoint: process.env.REACT_APP_LOGGING_ENDPOINT,
			// Do NOT put this on SILLY; too much traffic!
			level: process.env.REACT_APP_HTTP_LOGGING_LEVEL || 'info'
		})
	]
});

const locale = i18n.getLocale();
i18n.init(locale).then(() => {
	const render = () => {
		const App = require('./App').default;
		ReactDOM.render(
			<Provider store={store}>
				<App />
			</Provider>,
			document.getElementById('root')
		);
	};

	render();

	if (process.env.NODE_ENV === 'development') {
		const { hot } = module;
		if (hot) hot.accept('./App', render);
	}
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
