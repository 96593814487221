import React, { useState } from 'react';
import './FileUpload.scss';
import { t } from './../../libraries/i18n';
import { Spinner } from '@abm-international/react-components';

interface Props {
	onDrop: (files: FileList | null) => void,
	uploading: boolean,
	accept?: string,
	type ?: 'file' | 'image' | 'excel',
	displayStyle ?: 'button' | 'dragAndDrop'
}

export default function FileUpload(props: Props) {
	const [dragover, setDragover] = useState(false);

	if (props.displayStyle === 'button'){
		return (
			<div className="FileUpload buttonStyle">
				<input
					id="file"
					name="files"
					type="file"
					onChange={(e) => props.onDrop(e.target.files)}
					onDragOver={() => setDragover(true)}
					onDragLeave={() => setDragover(false)}
					disabled={props.uploading}
					accept={props.accept}
				/>
				<label htmlFor="file" className={`uploadButton ${dragover ? ' dragover': ''}${props.uploading ? ' loading': ''}`}>
					{!props.uploading && (
						<span className='Button border-only' >
							{props.type === 'file' && t('products.upload_file_button')}
							{props.type === 'image' && t('common.drag_and_drop_browse_image')}
							{props.type === 'excel' && t('dashboard.excel_action_upload')}
							{
								props.type !== 'image' &&
								props.type !== 'file' &&
								props.type !== 'excel' &&
								t('common.drag_and_drop_browse')
							}
						</span>
					)}
					{props.uploading && <Spinner />}
				</label>
			</div>
		);
	}

	return (
		<div className="FileUpload dragAndDrop">
			<input
				id="file"
				name="files"
				type="file"
				onChange={(e) => props.onDrop(e.target.files)}
				onDragOver={() => setDragover(true)}
				onDragLeave={() => setDragover(false)}
				disabled={props.uploading}
				accept={props.accept}
			/>
			<label htmlFor="file" className={`dropArea${dragover ? ' dragover': ''}${props.uploading ? ' loading': ''}`}>
				{!props.uploading && (
					<>
						<span>
							{props.type === 'file' && t('common.drag_and_drop_file')}
							{props.type === 'image' && t('common.drag_and_drop_image')}
							{props.type !== 'image' && props.type !== 'file' && t('common.drag_and_drop')}
							<span className='or'>{t('common.drag_and_drop_or')}</span>
						</span>
						<span className='Button border-only' >
							{props.type === 'file' && t('products.upload_file_button')}
							{props.type === 'image' && t('common.drag_and_drop_browse_image')}
							{props.type !== 'image' && props.type !== 'file' && t('common.drag_and_drop_browse')}
						</span>
					</>
				)}
				{props.uploading && <Spinner />}
			</label>
		</div>
	);
}
