import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './Technical.scss';
import { getLocale, t } from '../../libraries/i18n';
import { filterItemsByQuery, rankItems, sortItems } from '../../libraries/filter';
import { ListFilter, SafeImg } from './../../common';
import useSupplierRoute from './../../libraries/useSupplierRoute';
import { Button, List } from '@abm-international/react-components';
import { TechnicalStatus } from './../../interfaces/technical';
import { Product } from '../../interfaces';
import { useHistory } from 'react-router';
import { selectSupplierId } from '../../slices/suppliersSlice';
import { useProducts } from './../../api/technical';
import FileUpload from '../../common/FileUpload/FileUpload';
import {
	faFileDownload,
	faSort,
	faSortDown,
	faSortUp
} from '@fortawesome/free-solid-svg-icons';
import * as api from './../../libraries/api';
import { toast } from 'react-toastify';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function Technical() {
	useSupplierRoute();
	const history = useHistory();
	const supplierId = useSelector(selectSupplierId);
	// TODO: handle errors too
	const { products, isLoading /* , error */, mutate } = useProducts(supplierId);

	const [query, setQuery] = useState('');
	const [filter, setFilter] = useState<string | null>(null);

	const [sortKey, setSortKey] = useState('orderReference');
	const [sortDirection, setSortDirection] = useState(true);

	const [page, setPage] = useState(0);

	const [uploading, setUploading] = useState(false);
	const [excelLoading, setExcelLoading] = useState(false);

	const { height } = useWindowDimensions();
	// each item is 70px, the header is 70px and the footer is 60px
	const itemsPerPage = Math.floor(height / 70 - 2);

	useEffect(() => {
		setPage(0);
	}, [query, filter, sortKey, sortDirection, itemsPerPage]);

	const countTranslation = (amount: number) => {
		switch (amount) {
			case 0:
				return 'label_no_items';
			case 1:
				return 'label_one_item';
			default:
				return 'label_n_items';
		}
	};
	useEffect(() => {
		window.scrollTo(0, 0);
		const savedFilters = sessionStorage.getItem('technicalFilters');
		if (savedFilters){
			const {
				filter,
				query,
				sortDirection,
				sortKey
			} = JSON.parse(savedFilters);

			setQuery(query);
			setFilter(filter);
			setSortDirection(sortDirection);
			setSortKey(sortKey);
		}

	}, []);
	useEffect(() => {
		const technicalFilters = {
			filter,
			query,
			sortDirection,
			sortKey
		};
		sessionStorage.setItem('technicalFilters', JSON.stringify(technicalFilters));
	}, [filter, query, sortDirection, sortKey]);

	document.title = `${t('technical.title')} | Charles Supplier Portal`;

	const getButtonText = (status: TechnicalStatus) => {
		switch (status) {
			case TechnicalStatus.NEW:
				return 'products.product_action_edit';
			case TechnicalStatus.UNAPPROVED:
				return 'products.product_action_continue_edit';
			case TechnicalStatus.APPROVED:
				return 'products.product_action_view';
			case TechnicalStatus.ALMOST_EXPIRED :
				return 'products.product_action_expired';
		}
	};


	const getExcel = async () => {
		setExcelLoading(true);
		try {
			const { url } = await api.getExcel(supplierId);
			window.location.href = url;
		} catch (error){
			toast.error('Could not fetch template.');
		}
		setExcelLoading(false);
	};

	const uploadExcel = async (file: any | null): Promise<void> => {
		if (!file) return;
		const excel = new FormData();
		excel.append('excel', file);

		setUploading(true);
		try {
			const res = await api.uploadExcel(supplierId, excel);
			if (!res.success) throw res;
			await mutate();
			toast.success('products uploaded', { autoClose: 3000 });
			setFilter(TechnicalStatus.UNAPPROVED);
		} catch (e: any){
			toast.error(e);
			setFilter(TechnicalStatus.NEW);
		}
		setUploading(false);
	};

	const renderIntroduction = () => (
		<div className={'products__introduction'}>
			<p dangerouslySetInnerHTML={{ __html: t('products.introduction') }} />
		</div>
	);

	const renderHeader = ({ key, title }: {key: 'ref' | 'codeS' | 'codeW' | 'name', title: string}) => {
		const keyToSortKeyMap = {
			ref: 'orderReference',
			codeS: 'article.S.code',
			codeW: 'article.W.code',
			name: `article.S.description.${getLocale()}`
		};

		const sk = keyToSortKeyMap[key];
		const icon = sortKey === sk ? sortDirection ? faSortUp : faSortDown : faSort;
		return (
			<span
				className={'header_item'}
				onClick={() => {
					if (sortKey !== sk){
						setSortKey(sk);
						setSortDirection(true);
					} else {
						setSortDirection(!sortDirection);
					}
				}}
			>
				{title}
				<FontAwesomeIcon icon={icon} />
			</span>
		);
	};

	const renderSiteHeader = ({ title }: {title: string}) => {

		return (
			<div data-group={title} className={'header_group'}>
				{renderHeader({ key: 'codeS', title: 'st. nik' })}
				{renderHeader({ key: 'codeW', title: 'womm' })}
			</div>

		);
	};


	const listHeader = [
		{
			key: 'img',
			title: null,
			render: ({ images }: Product) => <SafeImg src={images.small} className={'thumbnail'} alt={''} />
		},
		{
			key: 'ref',
			title: t('products.product_ref'),
			render: ({ orderReference }: Product) => orderReference,
			renderHeader
		},
		{
			key: 'code',
			title: t('products.product_code'),
			render: ({ article }: Product) => (
				<>
					<span className={'siteCode S'}>{article.S.code}</span>
					<span className={'siteCode W'}>{article.W.code}</span>
				</>
			),
			renderHeader: renderSiteHeader
		},
		{
			key: 'name',
			title: t('products.product_name'),
			render: ({ article }: Product) => article?.S?.description?.[getLocale()],
			renderHeader
		},
		{
			key: 'status',
			title: null,
			render: ({ technical: { status } }: Product) => (
				<Button>
					<span className={`circle status--${status}`} />
					<span className={'text--status'}>{status && t(getButtonText(status))}</span>
				</Button>
			)
		}
	];

	const renderExcelPanel = () => {
		return (
			<section className={'excelPanel'}>
				<h2>{t('dashboard.excel_title')}</h2>
				<h3>{t('dashboard.excel_description')}</h3>
				<div className={'panel__actions'}>
					<Button
						onClick={getExcel}
						disabled={excelLoading}
						icon={<FontAwesomeIcon icon={faFileDownload} />}
					>
						{t('dashboard.excel_action_download')}
					</Button>
					<FileUpload
						onDrop={(fileList) => {
							if (fileList?.length) uploadExcel(fileList[0]);
						}}
						uploading={uploading}
						accept={'.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
						type={'excel'}
						displayStyle={'button'}
					/>

				</div>
			</section>
		);
	};



	const filters: Array<{value: string | null, amount: number, filtered: Product[]}> = [
		{
			value: null,
			amount: products.length,
			filtered: []
		},
		{
			value: TechnicalStatus.APPROVED,
			amount: products.filter(({ technical }) => technical.status === TechnicalStatus.APPROVED).length,
			filtered: []
		},
		{
			value: TechnicalStatus.UNAPPROVED,
			amount: products.filter(({ technical }) => technical.status === TechnicalStatus.UNAPPROVED).length,
			filtered: []
		},
		{
			value: TechnicalStatus.NEW,
			amount: products.filter(({ technical }) => technical.status === TechnicalStatus.NEW).length,
			filtered: []
		},
		{
			value: TechnicalStatus.ALMOST_EXPIRED,
			amount: products.filter(({ technical }) => technical.status === TechnicalStatus.ALMOST_EXPIRED).length,
			filtered: []
		}
	];

	const getFilteredProducts = (products: Array<Product>): Array<Product> | undefined => {
		if (isLoading) return;
		if (!products) return [];

		let filteredProducts = products.sort((a, b) => sortItems(a, b, sortDirection ? 'ascending' : 'descending', sortKey));


		if (query) {
			// @ts-ignore
			filteredProducts = filterItemsByQuery(query, filteredProducts, [`article.S.description.${getLocale()}`, 'article.S.code', 'article.W.code', 'orderReference'], true, 'AND');
			filteredProducts = filteredProducts.sort((a, b) => rankItems(a, b, sortDirection ? 'ascending' : 'descending', sortKey));
		}

		filters[0].filtered = filteredProducts;
		filters[1].filtered = filteredProducts.filter(({ technical }) => technical.status === TechnicalStatus.APPROVED);
		filters[2].filtered = filteredProducts.filter(({ technical }) => technical.status === TechnicalStatus.UNAPPROVED);
		filters[3].filtered = filteredProducts.filter(({ technical }) => technical.status === TechnicalStatus.NEW);
		filters[4].filtered = filteredProducts.filter(({ technical }) => technical.status === TechnicalStatus.ALMOST_EXPIRED);

		return filters.find(({ value }) => value === filter)?.filtered ?? [];
	};

	const filteredProducts = getFilteredProducts(products)?.map(product => ({ id: product.article.S.code, ...product }));

	return (
		<div className="Technical">
			{renderIntroduction()}
			<h2>{t('technical.title')}</h2>
			{!isLoading && <h3>{t(`products.${countTranslation(products?.length ?? 0)}`, products?.length ?? 0)}</h3> }
			{isLoading && <h3>loading ...</h3>}
			<ListFilter
				query={query}
				setQuery={setQuery}
				filters={filters}
				filter={filter}
				setFilter={setFilter}
			/>

			<List
				header={listHeader}
				content={filteredProducts}
				action={(_: any, { article }: Product) => {
					history.push(`technical/${article.id}`);
				}}
				gridTemplateColumns={'8rem 15rem 19rem 1fr 20rem'}
				pagination={{
					itemsPerPage,
					page,
					setPage
				}}
			/>
			{!isLoading && filteredProducts?.length === 0 && (
				<div className={'message'}>
					{t('commercial.label_no_products_found')}
				</div>
			)}
			{filteredProducts && products.some(({ technical: { status } }) => status !== TechnicalStatus.APPROVED && status !== TechnicalStatus.ALMOST_EXPIRED) && renderExcelPanel()}

		</div>
	);
}
