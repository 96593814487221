export default function BrowserConsoleTransport(options = {}) {
	const colors = {
		blue: '#61AFEF',
		red: '#E06C75',
		orange: '#D19A66',
		yellow: '#FFD399',
		green: '#98C379',
		gray: 'gray',
		white: 'white',
		lightgray: 'lightgray',
		background: 'black'
	};

	const getLoggerAndCSS = level => {
		switch (level) {
			case 'error': return {
				levelCSS: `color: ${colors.red}`,
				logToConsole: console.error,
			};
			case 'warn': return {
				levelCSS: `color: ${colors.yellow}`,
				logToConsole: console.warn
			};
			case 'info': return {
				levelCSS: `color: ${colors.green}`,
				logToConsole: console.info
			};
			case 'verbose': return {
				levelCSS: `color: ${colors.blue}`,
				logToConsole: console.debug
			};
			case 'debug': return {
				levelCSS: `color: ${colors.lightgray}`,
				logToConsole: console.debug
			};
			case 'silly': return {
				levelCSS: `color: ${colors.gray}`,
				logToConsole: console.debug
			};
			default: return {
				levelCSS: `color: ${colors.lightgray}`,
				logToConsole: console.log
			};
		}
	};

	return {
		name: options.name || 'BrowserConsoleTransport',
		level: options.level || 'info',
		log: (level, msg, meta) => {

			const { logToConsole, levelCSS } = getLoggerAndCSS(level);

			const messageStart = `%c[${level?.toUpperCase()}] %c${msg}`;
			const messageCSS = `color: ${colors.gray}`;

			logToConsole(messageStart, levelCSS, messageCSS, meta);
		}
	};
}
