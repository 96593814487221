import React from 'react';
import { useDispatch } from 'react-redux';
import './auth.scss';
import { Redirect, Route, Switch } from 'react-router-dom';
import Login from './login/Login';
import PasswordReset from './passwordReset/passwordReset';
import Forgot from './forgot/forgot';
import { setLocale } from './../../slices/userSlice';
import { Logout } from './logout/Logout';

export const Auth = () => {
	const dispatch = useDispatch();
	const locales = ['nl', 'fr', 'en'];

	const renderLocale = locale => (
		<button key={locale} onClick={() => dispatch(setLocale({ locale }))}>
			{locale}
		</button>
	);

	return (
		<div className={'Auth'}>
			<img src={'/CharlesLogo.svg'} alt="Charles Logo" className={'charles-logo'} />

			<div className={'auth-form'}>
				<Switch>
					<Route exact path='/auth/login' component={Login} />
					<Route exact path='/auth/logout' component={Logout} />
					<Route path='/auth/reset' component={PasswordReset} />
					<Route path='/auth/forgot' component={Forgot} />
					<Route path='/' render={() => (
						<Redirect to='/auth/login' />
					)} />
				</Switch>


			</div>
			<div className={'auth__locale'}>
				{locales && locales.map(renderLocale)}
			</div>
			{/*<VersionNumber />*/}
		</div>
	);
};

export default Auth;
