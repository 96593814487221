import React from 'react';
import './CharlesRoute.scss';
import { Route } from 'react-router-dom';
import { t } from '../../libraries/i18n';
import useMe from './../../api/useMe';

const getRouteVersion = (routes, path) => {
	const route = routes?.find(route => route.href === path);
	return route?.version;
};

function CharlesRoute(props) {
	const { me } = useMe();
	const routeMinimumVersion = getRouteVersion(me?.routes, props.path);

	const currentVersion = parseInt(process.env.REACT_APP_APP_VERSION);

	const isCompatibleVersion = () => {
		if (!routeMinimumVersion) return true;
		if (!currentVersion) return true;
		if (routeMinimumVersion <= currentVersion) return true;
		return false;
	};

	const reload = () => document.location.reload();

	const renderVersionError = () => (
		<div className='error__version'>
			<div className='error__text'>
				{t('errors.module_incompatible_version_text1', currentVersion, routeMinimumVersion)}
			</div>
			<div className={'error__hint'}>
				<button className='link' onClick={reload}>
					{t('errors.module_incompatible_version_reload_button')}
				</button>
				{t('errors.module_incompatible_version_text2')}
			</div>
		</div>
	);

	if (!isCompatibleVersion()) return renderVersionError();

	const renderRouteContent = () => {
		if (props.render) return props.render;

		const Component = props.component;
		return routeProps => <Component requiredPermission={props?.permission} {...routeProps} />;
	};

	return <Route
		path={props.path}
		render={renderRouteContent()}
	/>;
}

export default CharlesRoute;
