import './ArchiveLink.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHistory } from '@fortawesome/free-solid-svg-icons';
import { t } from '../../../libraries/i18n';

export default function ArchiveLink(props) {
	const {
		id,
		category,
		type
	} = props;

	return (
		<Link className={'ArchiveLink'} to={`/products/commercial/${id}/archive/${category}/${type}`}>
			<span className={'icon'}>
				<FontAwesomeIcon icon={faHistory} />
			</span>
			<span className={'text'}>{t(`commercial.archive_hint_${category}_${type}`)}</span>
		</Link>
	);
}
